<template>
  <div id="time_pc" class="block">
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in newdata.slice(0,6)"
        :key="index"
        :icon="item.icon"
        :type="item.type"
        :color="item.color"
        :nid="item.nid"
        :timestamp="newdata[index].dateTime"
      >
      <router-link :to="{name: 'Details' , params:{id: item.id}}">
        {{ newdata[index].title }}
      </router-link>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: ["1"],
      newdata:[{
        title:'',
        content:'',
        createtime:'',
        dateTime:''
      }],
      activities: [
        
      ],
    };
  },
  created() {
    this.getNew();
  },
  methods: {
    handleChange(val) {
      // console.log(val);
    },
    getNew(){
      this.$http.get('/new/getNew').then(res =>{
        // console.log(res)
        this.newdata = res.data.data
      })
    }
  },
};
</script>

<style>
a:link {color: white} /* 未被访问的链接 蓝色 */
a:visited {color: white} /* 已被访问过的链接 蓝色 */
.block {
  position: relative;left: 50%;
}
/* 时间线 */
.el-timeline-item__tail {
      /* border-left: 0.2vw solid white !important;
      left: 0.4vw !important; */
}
/* 圆点 */
.el-timeline-item__node--normal {
  
  /* width: 1vw !important;
  height: 1vw !important; */
}
/* 内容 */
#time_pc .el-timeline-item__timestamp {
    color: #729ae4!important;
    text-shadow: 0.1vw 0.2vw 0.2vw #000;
    margin-top: 1% !important;
    font-size: 0.8vw !important;
    
}
.el-timeline-item__content {
  /* color: white !important;
  overflow: hidden; */
    color: #fff!important;
    line-height: 23px;
    font-size: 17px;
    font-weight: lighter;
    text-overflow: ellipsis;
    /* 强制不换行 */
    /* white-space: nowrap; */
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    display: inline-block;
    cursor: pointer;
}
.el-collapse-item__header {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
  font-size: 1.4vw !important;
  height: 2vw !important;
}
.el-icon-arrow-right:before {
  content: none !important;
}
.el-collapse {
  border: none !important;
  width: 60vw;
  /* margin-left: 0px; */
}
.el-collapse-item__wrap {
  background-color: transparent !important;
  border: none !important;
  width: 40vw !important;
  margin-top: 1%;
  /* word-break: break-all; */
}
/* 标题 */
.el-collapse-item__content {
  /* height: 2vw !important; */
  color: white !important;
  font-size: 1vw !important;
  padding-bottom: 0px !important;
  /* width: 30vw !important;
  word-break: break-all !important; */
  /* 超出内容隐藏 */
  /* overflow:hidden; */
  /*让超出的内容用省略号显示*/
  /* text-overflow:ellipsis; */
  /*作为弹性伸缩盒子模型显示*/
  /* display:-webkit-box; */
  /*设置伸缩盒子的子元素排列方式--从上到下垂直排列*/
  /* -webkit-box-orient:vertical; */
  /*指定显示的多少行*/
  /* -webkit-line-clamp:2; */
}
.el-collapse-item{
  widows: 60vw !important;
}
.el-timeline-item__wrapper {
  margin-left: 0.75vw !important;
  top: -0.5vw !important;
  margin-bottom: 1.2vw;
}
</style>