<template>
  <div class="cpmo_h5 alm0_h5" style="overflow-x: hidden !important">
    <header_h5 />
    <el-table
      class="customer-table"
      :show-header="false"
      :data="mocpdata_h5"
      style="width: 100%; margin-top: 15px"
    >
      <el-table-column label="主图" width="130">
        <template slot-scope="scope" style="">
          <router-link style="display: inline-block;height: 100px;" :to="{ name: 'detcp_h5', params: { id: scope.row.id } }">
            <el-image
              :src="scope.row.imageName"
              fit="cover"
              style="width: 100px; height: 100px;"
            >
              <div slot="error">
                <img
                  src="../../assets/beijing/un_img.png"
                  style="width: 100%"
                />
              </div>
            </el-image>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column label="内容">
        <template slot-scope="scope" id="22444">
          <router-link :to="{ name: 'detcp_h5', params: { id: scope.row.id } }">
            <p
              class="c_bt_h5"
              :title="scope.row.title"
              style="text-align: left"
            >
              {{ scope.row.title }}
            </p>
          </router-link>
          <router-link :to="{ name: 'detcp_h5', params: { id: scope.row.id } }">
            <p class="c_jj_h5" style="text-align: left">
              {{ scope.row.synopsis }}
            </p>
          </router-link>
          <p class="c_sj_h5">{{ scope.row.dateTime }}</p>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import header_h5 from "../components_h5/header_h5.vue";
export default {
  data() {
    return {
      mocpdata_h5: [],
    };
  },
  created() {
    this.getmocp();
  },
  methods: {
    getmocp() {
      this.$http.get("/product/getProduct").then((res) => {
        // console.log(res)
        // this.mocpdata_h5 = res.data.data.length
        // console.log(this.mocpdata_h5)
        this.mocpdata_h5 = res.data.data;
        // console.log(this.mocpdata_h5)
        for (let item in this.mocpdata_h5) {
          this.mocpdata_h5[item].imageName =
            this.mocpdata_h5[item].imageName.split(",")[0];
        }
        // console.log(this.mocpdata_h5)
      });
    },
  },
  components: { header_h5 },
};
</script>

<style>
.alm0_h5 .cell {
  text-align: center;
}
.cpmo_h5 .el-table .el-table__cell{
  vertical-align: top !important;
}
.cpmo_h5 .cell {
  /* margin-top: -15px !important; margin-bottom: -30px; */
  margin-top: 0px !important;
}
.cpmo_h5 .el-table__row {
  height: 130px !important;
}
.c_bt_h5 {
  color: #333;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding-top: 10px; */
  vertical-align: top;
  
}
.c_jj_h5 {
  color: #666;
  font-size: 14px;
  margin: 1vw 0 2vw 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.c_sj_h5 {
  color: #999;
  /* float: right;
  margin-bottom: 2vw;
  right: 2vw; */
  font-size: 12px;
  position: absolute;
  right: 15px;
  bottom: 5px;
}
/* .customer-table th{
    border:none !important;
  }
.customer-table td,.customer-table th.is-leaf {
  border:none !important;
}
.el-table::before{
  height: 0px !important;
} */
</style>