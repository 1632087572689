<template>
    <div style="overflow-x: hidden !important;">
        <header_h5 />
        <div class="gy_xq_nr_h5" style="white-space: pre-wrap;" v-html="mogydata_h5.str3">
            <!-- {{mogydata_h5.str3}} -->
        </div>
        <div style="height:50px; width:100%;"></div>
    </div>
</template>

<script>
import header_h5 from "../components_h5/header_h5.vue"
export default {
    data() {
        return {
            mogydata_h5:[]
        }
    },
    created() {
        this.getmogy_h5();
    },
    methods: {
      getmogy_h5() {
          this.$http.get("/introduction/getIntroduction").then((res) => {
                // console.log(res)
                this.mogydata_h5 = res.data.data[0]
                // console.log(this.mogydata_h5)
            });
      } 
    },
    components:{header_h5}
}
</script>

<style>
.gy_xq_nr_h5{
    padding: 6vw 6vw 0 6vw; letter-spacing: 0.4vw; line-height: 25px; color: #333;
}
</style>