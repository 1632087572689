<template>
  <div>
    <div class="heard">
      <div class="newlb_left animate__animated animate__bounceIn">
        <img src="../../assets/logo/logo-04.png" alt="" />
      </div>
      <div class="newlb_right">
        <p class="newlb_w1 animate__animated animate__bounceIn ">
          铸行业之<span class="newlb_w2">冠</span>
        </p>
        <p class="newlb_w3 animate__animated animate__bounceIn">
          立精英之<span class="newlb_w4">锐</span>
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="margin-top: 4.38%"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
    </div>
    <div style="position: relative; padding: 3vw 0 0 7vw">
      <el-button
        class="xq_fh animate__animated animate__fadeIn"
        icon="el-icon-back"
        circle
        style="font-size: 2vw"
        onClick="javascript:history.back(-1);"
      ></el-button>
    </div>
    <div class="xq_h gy_xq_zj animate__animated animate__fadeInUp">
      <div class="gy_xq_tou">
        <p style="font-size: 2vw">关于冠锐</p>
      </div>
      <div class="gy_xq_nr" style="white-space: pre-wrap;" v-html="gydata.str3">
        <!-- {{ gydata.str3 }} -->
      </div>
    </div>
    <div style="height: auto; background: #070d21; position: relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="transform: scaleY(-1); margin-top: -0.5vw"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
      <Copyright />
    </div>
  </div>
</template>

<script>
import Copyright from "../../components/Copyright.vue";
export default {
  data() {
    return {
      gydata: [],
      gyxqdata:'',
    };
  },
  created() {
    this.getgy();
  },
  mounted() {},
  methods: {
    getgy() {
      this.$http.get("/introduction/getIntroduction").then((res) => {
        // console.log(res)
        this.gydata = res.data.data[0];
        // console.log(this.gydata.str3);
        
      });
    },
  },
  components: {
    Copyright,
  },
};
</script>

<style>
.xq_h {
  width: 100%; min-height: 50vw; margin-top: 3vw;
}
.gy_xq_zj {
   width: 100%;
}
.gy_xq_tou {
  width: 100%; text-align: center; margin-bottom: 2vw;
}
.gy_xq_nr {
  width: 75%; margin: 0 auto; font-size: 1.4vw; font-weight: lighter; letter-spacing: 0.2vw; text-indent: 2em; line-height: 2vw;
}
</style>