<template>
  <div class="h5_zt">
    <!-- 首页 -->
    <daohang_h5 />
    <div style="-webkit-overflow-scrolling: touch">
      <div id="m1_h5" class="m1_h5">
        <!-- 导航栏 -->
        <div class="container">
          <div class="wrap">
            <div class="cube">
              <div class="out-front">
                <img src="./images/t1.png" alt="" />
              </div>
              <div class="out-back">
                <img src="./images/t2.png" alt="" />
              </div>
              <div class="out-left">
                <img src="./images/t3.png" alt="" />
              </div>
              <div class="out-right">
                <img src="./images/t4.png" alt="" />
              </div>
              <div class="out-top">
                <img src="./images/t5.png" alt="" />
              </div>
              <div class="out-bottom">
                <img src="./images/t6.png" alt="" />
              </div>

              <span class="in-front">
                <img src="./images/t7.png" alt="" />
              </span>
              <span class="in-back">
                <img src="./images/t8.png" alt="" />
              </span>
              <span class="in-left">
                <img src="./images/t1.png" alt="" />
              </span>
              <span class="in-right">
                <img src="./images/t2.png" alt="" />
              </span>
              <span class="in-top">
                <img src="./images/t3.png" alt="" />
              </span>
              <span class="in-bottom">
                <img src="./images/t4.png" alt="" />
              </span>
            </div>
          </div>
        </div>

        <div class="s_h1_h5" style="text-align: center; top: 45vh">
          <div style="width: 70%; margin: 0 auto">
            <p
              class="s_w1_h5 animate__animated animate__fadeInLeftBig"
              style="text-align: left"
            >
              铸行业之
              <span class="s_w2_h5">冠</span>
            </p>
            <p
              class="s_w1_h5 animate__animated animate__fadeInLeftBig"
              style="text-align: right"
            >
              立精英之
              <span class="s_w2_h5">锐</span>
            </p>
          </div>
          <p class="s_w3_h5 animate__animated animate__fadeInRight">
            GIVE YOU INFINITE<br />
            POSSIBILITIES AND HOPE
          </p>
        </div>
        <div
          style="
            position: absolute;
            left: calc(50% - 45px);
            bottom: -30px;
            z-index: 10;
          "
        >
          <img style="width: 90px" src="../../assets/beijing/icon_str2.png" alt="" />
        </div>
      </div>

      <div id="m2_h5" class="m2_h5" style="width: 100%">
        <!-- 弧形 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          preserveAspectRatio="none"
          viewBox="0 0 1680 40"
          class="position-absolute width-full z-1"
          style="top: 89.1vh; position: absolute"
        >
          <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
        </svg>
        <div style="height: 50px"></div>
        <p class="g_w1_h5">关于<span class="g_w2_h5">冠锐</span></p>
        <p class="g_w3_h5">WELCOME TO THE FUTURE</p>
        <!-- 跳动方块 -->
        <!-- <div class="scene">
             <div class="cube-wrapper">
              <div class="cube">
               <div class="cube-faces">
                <div class="cube-face shadow" style="inset: 0;"></div>
                <div class="cube-face bottom" style="inset: 0;"></div>
                <div class="cube-face top" style="inset: 0;"></div>
                <div class="cube-face left" style="inset: 0;"></div>
                <div class="cube-face right" style="inset: 0;"></div>
                <div class="cube-face back" style="inset: 0;"></div>
                <div class="cube-face front" style="inset: 0;"></div>
               </div>
              </div>
             </div>
            </div> -->
        <div class="demo" style="min-height: 150px">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="loader">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 关于内容1和2 -->
        <div class="g_nr_h5" style="width: 100%">
          <div class="g_w4_h5">{{ gydata_h5.str1 }}</div>
          <div class="g_w5_h5">{{ gydata_h5.str2 }}</div>
        </div>
        <router-link to="/Moregy_h5">
          <div class="an_hz_h5">
            <el-button class="g_an_h5" type="primary" round
              >&nbsp;➜&nbsp;</el-button
            >
          </div>
        </router-link>
      </div>

      <div class="div_cont_h5">
        <!-- 新闻模块 -->
        <div id="m3_h5" style="height: 50px"></div>
        <div class="m3_h5">
          <p class="n_w1_h5"><span class="n_w2_h5">新闻</span>动态</p>
          <p class="n_w3_h5">WELCOME TO THE FUTURE</p>
          <div class="sj_h5" style="margin-top: 12%; width: 100%">
            <div style="width: 100%; margin: 0 auto"><time_h5 /></div>
          </div>
          <div class="an_hz_h5">
            <router-link to="/Morenews_h5">
              <el-button class="g_an_h5" type="primary" round>更多</el-button>
            </router-link>
          </div>
          <div id="m4_h5" style="height: 50px">
            <div class="cp_tp">
              <p class="icon_arrow" style="position: relative; top: 5vw"></p>
            </div>
          </div>
        </div>
        <!-- 产品模块 -->
        <div class="m4_h5">
          <p class="c_w1_h5">产品<span class="c_w2_h5">展示</span></p>
          <p class="c_w3_h5">PRODUCT SHOW</p>
          <!-- 展示卡 -->
          <!-- <div :class="cp_hz_h5[index]" v-for="(item, index) in prdata_h5.slice(0, 4)" :key="index"></div> -->
          <!-- 轮播展示 -->
          <cplbh5 />
          <div class="cp_an_hz_h5">
            <router-link to="/Morecp_h5">
              <el-button class="g_an_h5" type="primary" round>更多</el-button>
            </router-link>
          </div>
          <div id="m5_h5" style="height: 100px">
            <div class="cp_tb">
              <p class="icon_arrow" style="position: relative; top: 6vw"></p>
            </div>
          </div>
        </div>
        <!-- 案例模块 -->
        <div class="m5_h5">
          <p class="al_w1_h5"><span class="al_w2_h5">成功</span>案例</p>
          <p class="al_w3_h5">SUCCESSFUL CASE</p>
          <!-- 轮播展示 -->
          <allbh5 />
          <div class="cp_an_hz_h5">
            <router-link to="/Moreal_h5">
              <el-button class="g_an_h5" type="primary" round>更多</el-button>
            </router-link>
          </div>
          <div id="m6_h5" style="height: 50px">
            <div class="cp_tb">
              <p class="icon_arrow"></p>
            </div>
          </div>
        </div>
        <!-- 联系模块 -->
        <div class="m6_h5">
          <p class="l_w1_h5">联系我们</p>
          <p class="l_w2_h5">CONTACT US</p>
          <img
            class="l_tp1_h5"
            src="http://qigugu.ifchus.com/icon_str3.png"
            alt=""
          />
          <img
            class="l_tp2_h5 ttt"
            src="../../assets/beijing/icon_str3ball.png"
            alt=""
          />
          <div class="l_h2_h5">
            <p
              class="l_w3_h5"
              style="white-space: pre-wrap"
              v-html="lxdata_h5.str"
            ></p>
          </div>
        </div>
      </div>

      <div class="footer_h5">
        <div class="footer_top_h5">
          <p class="f_w1_h5">合作伙伴</p>
          <p class="f_w2_h5">cooperative partner</p>
          <div class="div_content_h5">
            <div class="mine_h5">冠锐</div>
            <ul class="friends_ul_h5">
              <li v-for="(item, index) in friends_h5" :key="index">
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="footer_bottom_h5" style="padding-bottom: 4vh">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            preserveAspectRatio="none"
            viewBox="0 0 1680 40"
            aria-hidden="true"
            class="position-absolute top-0 width-full mt-n1"
            style="
              transform: scaleY(-1);
              position: relative;
              top: -1px;
              vertical-align: top;
            "
          >
            <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
          </svg>
          <img
            style="width: 10vh; margin-top: 2vh; padding-bottom: 3vh"
            src="./images/footer_img_h5.png"
            alt=""
          />
          <p>© 2022 kuary 冠锐 闽ICP备12020945号</p>
          <p>福建冠锐网络技术有限公司</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入css文件
// import "@/css/block3d.css";
import "../CSS_h5/fk.css";
import "../CSS_h5/m1_h5.css";
import "../CSS_h5/m2_h5.css";
import "../CSS_h5/m3_h5.css";
import "../CSS_h5/m4_h5.css";
import "../CSS_h5/m5_h5.css";
import "../CSS_h5/m6_h5.css";
import "../CSS_h5/xz_h5.css";
import "../CSS_h5/footer_h5.css";

// 引入组件
import time_h5 from "../components_h5/time_h5.vue";
import cplbh5 from "../components_h5/cp_lb_h5.vue";
import allbh5 from "../components_h5/al_lb_h5.vue";
import daohang_h5 from "../components_h5/daohang_h5.vue";

export default {
  data() {
    return {
      gydata_h5: [],
      prdata_h5: [],
      lxdata_h5: [],
      friends_h5: [
        { name: "福建省气象局" },
        { name: "福州海关" },
        { name: "福建省铁通" },
        { name: "福建省民政厅" },
        { name: "宁德新能源科技有限公司" },
        { name: "杭州帕拉迪网络科技有限公司" },
      ],
      test_s_h5: "",
      drawer: false,
      //  cp_hz_h5: ["c_h1_h5", "c_h2_h5", "c_h3_h5", "c_h4_h5"],
    };
  },
  created() {
    this.getcx();
  },
  methods: {
    getcx() {
      // 关于冠锐查询
      this.$http.get("/introduction/getIntroduction").then((res) => {
        // console.log(res)
        this.gydata_h5 = res.data.data[0];
        // console.log(this.gydata_h5)
      });
      // 产品展示查询
      this.$http.get("/product/getProduct").then((res) => {
        // console.log(res)
        // this.test_s_h5 = res.data.data.length
        // console.log(this.test_s_h5)
        this.prdata_h5 = res.data.data;
        // console.log(this.prdata_h5)
        for (let item in this.productdata) {
          this.productdata[item].imageName =
            this.productdata[item].imageName.split(",")[0];
        }
      });
      this.$http.get("/contact/getContact").then((res) => {
        // console.log(res)
        this.lxdata_h5 = res.data.data;
        // console.log(this.lxdata_h5)
      });
    },
  },
  // 注册插件
  components: { time_h5, cplbh5, allbh5, daohang_h5 },
};
</script>

<style>
.div_cont_h5 {
  padding: 0 8px;
}
.h5_zt .icon_arrow {
  opacity: 0.4;
  width: 25px;
}
</style>

<style>
@-webkit-keyframes rotation {
  /* 执行开始位置 */
  /* from {transform: rotatez(0deg) !important;} */
  /* 结束位置 */
  /* to {transform: rotatez(360deg) !important;} */
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.ttt {
  /* -webkit-animation: rotation 15s linear infinite;
        animation: rotation 15s linear infinite; */
  /* transform-origin: left bottom; */
  /* transition: transform 2s; */
  animation-name: rotate;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  cursor: pointer;
}
</style>