<template>
    <div>
        <!-- 顶部 -->
        <div class="banner_centan">

            <!-- <el-card class="box-card"> -->
                <p class="project_name"><span> 项目名称：</span>{{ modelasdasdad.title }}</p>
                <p class="earnest_money"> <span>保证金：￥</span>{{ modelasdasdad.jine }}</p>
                <div class="project_company">
                <el-tabs>
                    <el-tab-pane label="项目详情">{{ modelasdasdad.company }}</el-tab-pane>
                    <el-tab-pane label="联系我们">{{ modelasdasdad.connectUs }}</el-tab-pane>

                </el-tabs>
            </div>
                <!-- <p class="item_description">项目详情：{{ modelasdasdad.company }}</p> -->

                <p style="text-align: right;margin-top: 10px;">
                    <!-- <router-link :to="{ name: 'About' }"> -->
                        <el-button type="success"  style="width: 150px;height: 50px;" @click="btnFun">微信支付</el-button>
                    <!-- </router-link> -->
                </p>
            <!-- </el-card> -->
            <!-- <el-card class="box-card" style="margin-top: 10px;">
                <p>项目名称：{{ modelasdasdadSSS.title }}</p>
                <p style="margin-top: 10px; white-space: nowrap;  
    overflow: hidden;  
    text-overflow: ellipsis; ">项目内容：{{ modelasdasdadSSS.company }}</p>
                <p>保证金：{{ modelasdasdad.jine }}</p>
                <p style="text-align: right;margin-top: 10px;">
                    <router-link :to="{ name: 'About' }">
                        <el-button type="success" style="width: 100px;height: 40px;">去支付</el-button>
                    </router-link>
                </p>
            </el-card> -->

            <!-- <div class="centan_Box">
                <el-card class="box-card">
                    <p>项目名称：{{ modelasdasdad.title }}</p>
                    <p style="display: flex;justify-content: space-between;">
                        <span>投标公司：{{ modelasdasdad.company }}</span><span>联系人：{{ modelasdasdad.contacts
                        }}</span><span>电话：{{
    modelasdasdad.tel }}</span>
                    </p>
                    <p>保证金用途：作为投标人参与招标的一种担保，确保投标人的诚意和真实意愿</p>
                    <p style="text-align: right;">金额：<span style="color: red;font-size: 20px; font-weight: bold;">￥ <span style="font-size: 32px;">100.00</span></span></p>
                    <p style="text-align: right;"><el-button type="success" @click="btnFun" style="width: 150px;height: 50px;">微信支付</el-button></p>
                </el-card>
            </div> -->

            <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="方式一" name="first">
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="经度">
                            <el-input v-model="formInline.longitude" placeholder="经度"></el-input>
                        </el-form-item>
                        <el-form-item label="纬度">
                            <el-input v-model="formInline.latitude" placeholder="纬度"></el-input>
                        </el-form-item>
                        <el-form-item label="半径">
                            <el-input v-model="formInline.radius" placeholder="半径"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit(1)">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-image style="width: 500px; height: auto" :src="Imgurl" :preview-src-list="srcList"></el-image>
                </el-tab-pane>
                <el-tab-pane label="方式二" name="second">
                    <el-form :inline="true" :model="formER" class="demo-form-inline">
                        <el-form-item label="区域">
                            <el-cascader v-model="formER.code" ref="cascaderAre" :options="areAll" :props="areaOptionsProps"
                                @change="seletHandleChange" :show-all-levels="false" placeholder="请选择行政区"
                                :clearable="true"></el-cascader>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit(2)">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-image style="width: 500px; height: auto" :src="ImgERurl" :preview-src-list="srcERList"></el-image>
                </el-tab-pane>
            </el-tabs> -->



        </div>
    </div>
</template>

<script>
export default {
    name: "AnnounceDepositPayment",
    components: {
        // AnnounceFileDownload,
    },
    data() {
        return {
            areAll: [
                {
                    id: "35",
                    label: "福建省",
                    children: [
                        {
                            id: "3501",
                            label: "福州市",
                            children: [
                                {
                                    id: "350102",
                                    label: "鼓楼区"
                                },
                                {
                                    id: "350103",
                                    label: "台江区"
                                },
                                {
                                    id: "350104",
                                    label: "仓山区"
                                },
                                {
                                    id: "350105",
                                    label: "马尾区"
                                },
                                {
                                    id: "350111",
                                    label: "晋安区"
                                },
                                {
                                    id: "350112",
                                    label: "长乐区"
                                },
                                {
                                    id: "350121",
                                    label: "闽侯县"
                                },
                                {
                                    id: "350122",
                                    label: "连江县"
                                },
                                {
                                    id: "350123",
                                    label: "罗源县"
                                },
                                {
                                    id: "350124",
                                    label: "闽清县"
                                },
                                {
                                    id: "350125",
                                    label: "永泰县"
                                },
                                {
                                    id: "350128",
                                    label: "平潭县"
                                },
                                {
                                    id: "350181",
                                    label: "福清市"
                                }
                            ]
                        },
                        {
                            id: "3502",
                            label: "厦门市",
                            children: [
                                {
                                    id: "350203",
                                    label: "思明区"
                                },
                                {
                                    id: "350205",
                                    label: "海沧区"
                                },
                                {
                                    id: "350206",
                                    label: "湖里区"
                                },
                                {
                                    id: "350211",
                                    label: "集美区"
                                },
                                {
                                    id: "350212",
                                    label: "同安区"
                                },
                                {
                                    id: "350213",
                                    label: "翔安区"
                                }
                            ]
                        },
                        {
                            id: "3503",
                            label: "莆田市",
                            children: [
                                {
                                    id: "350302",
                                    label: "城厢区"
                                },
                                {
                                    id: "350303",
                                    label: "涵江区"
                                },
                                {
                                    id: "350304",
                                    label: "荔城区"
                                },
                                {
                                    id: "350305",
                                    label: "秀屿区"
                                },
                                {
                                    id: "350322",
                                    label: "仙游县"
                                }
                            ]
                        },
                        {
                            id: "3504",
                            label: "三明市",
                            children: [
                                {
                                    id: "350402",
                                    label: "梅列区"
                                },
                                {
                                    id: "350403",
                                    label: "三元区"
                                },
                                {
                                    id: "350421",
                                    label: "明溪县"
                                },
                                {
                                    id: "350423",
                                    label: "清流县"
                                },
                                {
                                    id: "350424",
                                    label: "宁化县"
                                },
                                {
                                    id: "350425",
                                    label: "大田县"
                                },
                                {
                                    id: "350426",
                                    label: "尤溪县"
                                },
                                {
                                    id: "350427",
                                    label: "沙县"
                                },
                                {
                                    id: "350428",
                                    label: "将乐县"
                                },
                                {
                                    id: "350429",
                                    label: "泰宁县"
                                },
                                {
                                    id: "350430",
                                    label: "建宁县"
                                },
                                {
                                    id: "350481",
                                    label: "永安市"
                                }
                            ]
                        },
                        {
                            id: "3505",
                            label: "泉州市",
                            children: [
                                {
                                    id: "350502",
                                    label: "鲤城区"
                                },
                                {
                                    id: "350503",
                                    label: "丰泽区"
                                },
                                {
                                    id: "350504",
                                    label: "洛江区"
                                },
                                {
                                    id: "350505",
                                    label: "泉港区"
                                },
                                {
                                    id: "350521",
                                    label: "惠安县"
                                },
                                {
                                    id: "350524",
                                    label: "安溪县"
                                },
                                {
                                    id: "350525",
                                    label: "永春县"
                                },
                                {
                                    id: "350526",
                                    label: "德化县"
                                },
                                {
                                    id: "350581",
                                    label: "石狮市"
                                },
                                {
                                    id: "350582",
                                    label: "晋江市"
                                },
                                {
                                    id: "350583",
                                    label: "南安市"
                                }
                            ]
                        },
                        {
                            id: "3506",
                            label: "漳州市",
                            children: [
                                {
                                    id: "350602",
                                    label: "芗城区"
                                },
                                {
                                    id: "350603",
                                    label: "龙文区"
                                },
                                {
                                    id: "350622",
                                    label: "云霄县"
                                },
                                {
                                    id: "350623",
                                    label: "漳浦县"
                                },
                                {
                                    id: "350624",
                                    label: "诏安县"
                                },
                                {
                                    id: "350625",
                                    label: "长泰县"
                                },
                                {
                                    id: "350626",
                                    label: "东山县"
                                },
                                {
                                    id: "350627",
                                    label: "南靖县"
                                },
                                {
                                    id: "350628",
                                    label: "平和县"
                                },
                                {
                                    id: "350629",
                                    label: "华安县"
                                },
                                {
                                    id: "350681",
                                    label: "龙海市"
                                }
                            ]
                        },
                        {
                            id: "3507",
                            label: "南平市",
                            children: [
                                {
                                    id: "350702",
                                    label: "延平区"
                                },
                                {
                                    id: "350703",
                                    label: "建阳区"
                                },
                                {
                                    id: "350721",
                                    label: "顺昌县"
                                },
                                {
                                    id: "350722",
                                    label: "浦城县"
                                },
                                {
                                    id: "350723",
                                    label: "光泽县"
                                },
                                {
                                    id: "350724",
                                    label: "松溪县"
                                },
                                {
                                    id: "350725",
                                    label: "政和县"
                                },
                                {
                                    id: "350781",
                                    label: "邵武市"
                                },
                                {
                                    id: "350782",
                                    label: "武夷山市"
                                },
                                {
                                    id: "350783",
                                    label: "建瓯市"
                                }
                            ]
                        },
                        {
                            id: "3508",
                            label: "龙岩市",
                            children: [
                                {
                                    id: "350802",
                                    label: "新罗区"
                                },
                                {
                                    id: "350803",
                                    label: "永定区"
                                },
                                {
                                    id: "350821",
                                    label: "长汀县"
                                },
                                {
                                    id: "350823",
                                    label: "上杭县"
                                },
                                {
                                    id: "350824",
                                    label: "武平县"
                                },
                                {
                                    id: "350825",
                                    label: "连城县"
                                },
                                {
                                    id: "350881",
                                    label: "漳平市"
                                }
                            ]
                        },
                        {
                            id: "3509",
                            label: "宁德市",
                            children: [
                                {
                                    id: "350902",
                                    label: "蕉城区"
                                },
                                {
                                    id: "350921",
                                    label: "霞浦县"
                                },
                                {
                                    id: "350922",
                                    label: "古田县"
                                },
                                {
                                    id: "350923",
                                    label: "屏南县"
                                },
                                {
                                    id: "350924",
                                    label: "寿宁县"
                                },
                                {
                                    id: "350925",
                                    label: "周宁县"
                                },
                                {
                                    id: "350926",
                                    label: "柘荣县"
                                },
                                {
                                    id: "350981",
                                    label: "福安市"
                                },
                                {
                                    id: "350982",
                                    label: "福鼎市"
                                }
                            ]
                        }
                    ]
                }
            ],
            areaOptionsProps: {
                //绑值
                value: "id",
                // 静态
                // value:'label',
                label: "label",
                children: "children",
                //多选
                multiple: false,

                checkStrictly: true,
                //触发子菜单
                expandTrigger: "hover",
            },
            title: "支付保证金",
            visible: false,
            model: {},
            modelasdasdad: {
                title: "福建中实招标有限公司关于福建农业职业技术学院第四食堂经营招标",
                company: "1.配送品种：肉类、鱼虾类、蔬菜瓜果类、蛋类、豆类、粮油面、调料、预包装食品等。2.配送数量：2300人左右工作日午餐所需食材。3.质量要求：（1）猪肉、牛羊肉、禽肉类必须两证齐全（双证图片每天同货物同时送达）、感官无异常；鱼虾类须提供活鱼虾或符合要求的新鲜度（每天检测报告同货物同时送达）。（2）蔬菜瓜果：属无公害蔬菜水果、应当无毒无害，具有相应的色、香、味等感官性状并保证优良的色泽和新鲜度（每天检测报告同货物同时送达）。（3）冷冻类及干货类：保持优良的外观和等级，配送到学校时保质期剩余时间必须在原有保质期的1/2以上。（4）所有食材质量必须符合《食品安全法》相关规定和要求。",
                contacts: "董某",
                connectUs: "采购代理人：福建中实招标有限公司            地址：华林路201号华林大厦1002 邮编：350003电话：0591-87767686转8625 传真：0591-87845818",
                tel: "17563486524",
                jine: 100
            },
            modelasdasdadSSS: {
                title: "福建中实招标有限公司关于福建船政职业技术学院第四食堂经营招标",
                company: "1.配送品种：肉类、鱼虾类、蔬菜瓜果类、蛋类、豆类、粮油面、调料、预包装食品等。2.配送数量：2300人左右工作日午餐所需食材。3.质量要求：（1）猪肉、牛羊肉、禽肉类必须两证齐全（双证图片每天同货物同时送达）、感官无异常；鱼虾类须提供活鱼虾或符合要求的新鲜度（每天检测报告同货物同时送达）。（2）蔬菜瓜果：属无公害蔬菜水果、应当无毒无害，具有相应的色、香、味等感官性状并保证优良的色泽和新鲜度（每天检测报告同货物同时送达）。（3）冷冻类及干货类：保持优良的外观和等级，配送到学校时保质期剩余时间必须在原有保质期的1/2以上。（4）所有食材质量必须符合《食品安全法》相关规定和要求。",
                contacts: "董某",
                tel: "17563486524",
                jine: 100
            },
            QrListArr: ["wx.jpg", "zfb.png"],
            needInvoice: "check",
            NoNeedInvoice: "",
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            confirmLoading: false,
            userInformation: {},
            // form: this.$form.createForm(this),
            validatorRules: {
                company: [
                    { required: true, message: '请输入公司名称!' },
                    { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
                ],
                email: [
                    { required: true, type: 'email', message: '邮箱格式不正确！', trigger: 'blur' }
                ],
                // assertImgs: [
                //   { required: true, message: '请上传营业执照！' }
                // ],
                tel: [
                    { required: true, validator: this.validateMobile, trigger: 'blur' }
                ],
                contacts: [
                    { required: true, message: '请输入联系人!' },
                    { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
            },
            activeName: 'first',
            formInline: {
                longitudej: '',
                latitude: '',
                radius: ''
            },
            formER: {
                code: "",
                codeid: "",
            },
            Imgurl: 'https://w.wallhaven.cc/full/we/wallhaven-wex8z7.jpg',
            srcList: [
            ],
            ImgERurl: 'https://w.wallhaven.cc/full/rr/wallhaven-rr67m1.jpg',
            srcERList: [
                'https://w.wallhaven.cc/full/rr/wallhaven-rr67m1.jpg',

            ],
            url: {
                add: "/test/jeecgDemo/add",
                edit: "/test/jeecgDemo/edit",
            },
        }
    },
    created() {
    },
    methods: {
        validateMobile(rule, value, callback) {
            if (new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)) {
                callback();
            } else {
                callback("您的手机号码格式不正确!");
            }
        },
        add() {
            this.edit({});
        },
        edit(record) {
            console.log("报名传参", record)
            this.model = Object.assign({}, record);
            this.visible = true;
        },
        close() {
            // this.$refs.form.resetFields();
            this.$emit('close');
            this.visible = false;
        },
        btnFun(lisf) {
          this.$confirm('将打开微信进行支付, 是否继续?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          this.$message.warning("功能开发中");
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });          
      });


          
      },
        onSubmit(type) {
            console.log('submit!');
            const loading = this.$loading({
                lock: true,
                text: '生成图片中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            if (type == 1) {
                this.srcList = []
                this.$http.get("/system/ProvinceShp/getRoseDiagram", {
                    responseType: 'blob',
                    params: { longitude: this.formInline.longitude, latitude: this.formInline.latitude, radius: this.formInline.radius }
                }).then((res) => {
                    console.log(res.data)
                    const imageUrl = URL.createObjectURL(res.data);
                    this.Imgurl = imageUrl
                    this.srcList.push(imageUrl)
                    loading.close();
                })
            }
            if (type == 2) {
                this.srcERList = []
                this.$http.get("/system/ProvinceShp/getRoseDiagram", {
                    responseType: 'blob',
                    params: { areaCode: this.formER.code[this.formER.code.length - 1] }
                }).then((resER) => {
                    console.log(resER.data)
                    const imageERUrl = URL.createObjectURL(resER.data);
                    this.ImgERurl = imageERUrl
                    this.srcERList.push(imageERUrl)
                    loading.close();
                })
            }
        },
        // 导出按钮的回调函数中
        imgListData(response, name, suffix) {
            //  如果支持微软的文件下载方式(ie10+浏览器)
            if (window.navigator.msSaveBlob) {
                try {
                    const blobObject = new Blob([response]);
                    window.navigator.msSaveBlob(blobObject, `${name}.zip`);
                } catch (e) {
                    console.log(e);
                }
            } else {
                var blob = new Blob([response]);
                var downloadElement = document.createElement("a");
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download = name + suffix; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
                this.$message({
                    message: "下载成功",
                    type: "success",
                });
            }
        },
        seletHandleChange(e, s) {
            console.log(e, s, this.formER)
            this.$refs.cascaderAre.dropDownVisible = false;
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleOk() {
            const that = this;
            // 触发表单验证
            this.$refs.form.validate(valid => {
                if (valid) {
                    //   that.confirmLoading = true;
                    //   let httpurl = '';
                    //   let method = '';
                    //   if(!this.model.id){
                    //     httpurl+=this.url.add;
                    //     method = 'post';
                    //   }else{
                    //     httpurl+=this.url.edit;
                    //      method = 'put';
                    //   }
                    console.log("校验")
                    //   httpAction(httpurl,this.model,method).then((res)=>{
                    //     if(res.success){
                    //       that.$message.success(res.message);
                    that.$emit('ok');
                    this.visible = false;
                    //     }else{
                    //       that.$message.warning(res.message);
                    //     }
                    //   }).finally(() => {
                    //     that.confirmLoading = false;
                    //     that.close();
                    //   })
                }
            })
        },
        handleCancel() {

        }
    }
}
</script>
<style>
.el-message-box {
    width: auto !important;
}
</style>
<style scoped>
.announce_header {
    width: 100%;
    height: 88px;
    /* background-color: pink; */
    display: flex;
    background: #0f0c29;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #24243e,
            #302b63,
            #0f0c29);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.announce_header_left {
    width: 40%;
    height: 100%;
    /* background-color: red; */
    padding-left: 45px;
}

.announce_header_left_h1 {
    color: white;
    line-height: 88px;
    font-size: 25px;
}

.announce_header_right {
    width: 60%;
    height: 100%;
    /* background-color: blue; */
}

.announce_header_right_btnone {
    text-align: right;
    margin-top: 55px;
    margin-right: 45px;
}

.midland_one {
    width: 100%;
    display: flex;
}

.midland_one_left {
    width: 70%;
    height: 66px;
    padding-left: 45px;
    line-height: 66px;
    font-size: 16px;
}

.midland_one_right {
    width: 30%;
    height: 66px;
    text-align: right;
    line-height: 63px;
}

.midland_three {
    padding: 0 30px;
}

.registrationselectionone,
.registrationselectiontwo {
    width: 200px;
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* 横向偏移量 | 纵向偏移量 | 模糊半径 | 阴影颜色 */
}

.banner_centan {
    width: 90%;
    /* margin: 40px 20px 20px 20px; */
    text-align: left;
    margin: 0px auto;
    font-size: 14px;
}

.centan_Box {
    width: 80%;
    margin: 0 auto;
    line-height: 50px;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: left;
}

.project_name {
    font-size: 20px;
}

.project_name span {
    font-size: 14px;
}

.earnest_money {
    font-size: 20px;
    font-weight: bold;
}

.earnest_money span {
    font-size: 14px;
    font-weight: normal;
}
.project_company{
    margin-top: 10px;
}
</style>