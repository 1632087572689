<template>
  <div id="daohang">
      <div class="nav">
          <div class="container_top">
          <img class="tp1" src="http://qigugu.ifchus.com/logo-04.png" alt="">
            <ul class="dh1">
                <li onclick="javascript:document.getElementById('m1').scrollIntoView({behavior:'smooth'})"><a href="javascript:void(0)">首页</a></li>
                <li onclick="javascript:document.getElementById('m2').scrollIntoView({behavior:'smooth'})"><a href="javascript:void(0)">关于冠锐</a></li>
                <li onclick="javascript:document.getElementById('m3').scrollIntoView({behavior:'smooth'})"><a href="javascript:void(0)">新闻动态</a></li>
                <li onclick="javascript:document.getElementById('m4').scrollIntoView({behavior:'smooth'})"><a href="javascript:void(0)">产品展示</a></li>
                <li onclick="javascript:document.getElementById('m5').scrollIntoView({behavior:'smooth'})"><a href="javascript:void(0)">成功案例</a></li>
                <li onclick="javascript:document.getElementById('m6').scrollIntoView({behavior:'smooth'})"><a href="javascript:void(0)">联系我们</a></li>
            <div class="animation start-首页"></div>
        </ul>
    </div>
      </div>
      
  </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
  
}
</script>

<style>
.dh1{
    position: relative;
    color: white;
    width: 75%;
    height: 50px;
    float: right;
}
.dh1 li{
    list-style: none;
    float: left;
    font-size: 1.2vw;
    margin-left: 3%;
    position: relative;
    top: 20%;
    left: 35%;
    /* opacity:0.8; */
}
.dh1 a{
  text-decoration: none;
  color: #fff;
  transition-duration: 0.5s;
  font-weight: lighter;
}
.tp1{
    height: 7vh;
    position: relative;
    left: 10%;
    
}
.dh1 a:hover {
  padding: 0px 0px 6px 0px;
  border-bottom:1px solid rgb(255, 255, 255);
  transition-duration: 0.5s;
}

@media screen and (max-width:750px) {
  .tp1{
    height: 25px;
    width: 100px;
    position: relative;
    left: 1%;
  }
  .dh1{
    position: relative;
    color: white;
    width: 350px;
    height: 50px;
    left: 13%;
  }
  .dh1 li{
    font-size: 12px;
    float: left;
    margin-left: 5px;
    top: -50%;
  }
}
</style>