<template>
  <div class="tpcd">
    <el-dropdown>
      <el-button type="primary">
        <div class="kuang"></div>
      </el-button>
      <el-dropdown-menu class="dhbj" slot="dropdown">
        <el-dropdown-item onclick="javascript:document.getElementById('m1').scrollIntoView({behavior:'smooth'})">首页</el-dropdown-item>
        <el-dropdown-item onclick="javascript:document.getElementById('m2').scrollIntoView({behavior:'smooth'})">关于冠锐</el-dropdown-item>
        <el-dropdown-item onclick="javascript:document.getElementById('m3').scrollIntoView({behavior:'smooth'})">新闻动态</el-dropdown-item>
        <el-dropdown-item onclick="javascript:document.getElementById('m4').scrollIntoView({behavior:'smooth'})">产品展示</el-dropdown-item>
        <el-dropdown-item onclick="javascript:document.getElementById('m5').scrollIntoView({behavior:'smooth'})">成功案例</el-dropdown-item>
        <el-dropdown-item onclick="javascript:document.getElementById('m6').scrollIntoView({behavior:'smooth'})">联系我们</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* .tbdh1{
    width: 50px;
    height: 50px;
} */
.el-button--primary{
    background-color: transparent !important;
    border: transparent !important;
    
}
.kuang{
    background: url(../assets/logo/icon_menu1.png) 50% no-repeat;
    border: 0.15vw solid #fff;
    border-radius: 3vw;
    width: 3vw;
    height: 3vw;
    background-size: 3vw;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
    transition: all 250ms cubic-bezier(.02, .01, .47, 1);
}
.dhbj{
    background-color: transparent !important;
    border: transparent !important;
}
.el-dropdown-menu__item{
    color: white !important;
    font-size: 1vw !important;
    line-height: 2vw !important;
}
.el-dropdown-menu__item:hover{
    background-color: transparent !important;
    color: orangered !important;
}
.el-dropdown-menu{
  border-bottom: 0.01vw solid #fff!important;
}
.kuang:hover{
  box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
  transform: translate(0,-5px);
  transition-delay: 0s !important;
}

</style>