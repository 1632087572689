<template>
  <!-- <div class="cplbzt">
    <div class="block">
      <el-carousel :autoplay="false" :loop="false" type="card" @change="carouselChange" ref="slideCarousel" height="175px" indicator-position="none" arrow="never">
        <el-carousel-item :id="gd[index]" :class="gd[index]" v-for="(item, index) in cplbdata.slice(0, 4)" :key="index" >
          <el-image class="cptp_h5" fit="fill" :src="item.imageName" alt=""></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div> -->
  <div class="my-container">
    <div class="banner-box-swiper">
      <!-- 这里一定要加 (v-if="carouselList.length > 0") 判断 否则 loop 循环就会失效 （使用静态轮播图数据不会出现这种情况）
      原因：动态获取轮播图数据，数据还没拿到，dom已经加载完成，所以loop会失效
       -->
      <swiper
        v-if="cplbdata.length > 0"
        :options="swiperOption"
        ref="mySwiper"
        style="height: 180px"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="(item,i) in cplbdata.slice(0, 6)"
          :key="i"
        >
        <router-link :to="{name:'detcp_h5',params:{id: cplbdata[i].id}}">
          <a :href="item.redirectUrl"><img :src="item.imageName" alt="" /></a>
        </router-link>
        </swiper-slide>
      </swiper>
    </div>
    <!-- <div class="indicators">
      <div
        :class="'item ' + (carouselIndex == index ? 'active' : '')"
        v-for="(_, index) in carouselList.length"
        :key="index"
      ></div>
    </div> -->
  </div>
</template>

<script>
import $ from "jquery";

import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

let vm = null;
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      cplbdata: [],
      
      active: 0,
      carouselIndex: 0,
      // 异形轮播图配置
      swiperOption: {
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0, // slide做3d旋转时Y轴的旋转角度 (默认50).
          stretch: -20, // 每个slide之间的拉伸值 越大slide靠得越紧.
          depth: 100, // slide的位置深度 值越大z轴距离越远 看起来越小.
          modifier: 2, // depth和rotate和stretch的倍率 相当于(depth*modifier、rotate*modifier、stretch*modifier) 值越大这三个参数的效果越明显(默认1).
          slideShadows: false, // 开启slide阴影 (默认 true)
        },
        // 默认选中中间一张
        centeredSlides: true,
        // 无限循环
        loop: false,
        // 自动轮播
        autoplay: false,
        // 解开可以自动轮播
        // {
        //   delay: 5000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        slideToClickedSlide: true,
        on: {
          slideChange: function () {
            // console.log(this.realIndex);
            vm.carouselIndex = this.realIndex;
          },
        },
      },
    };
  },
  created() {
    vm = this;
    this.getcplb();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // this.getCarouselList();
  },
  methods: {
    // 轮播图
    getcplb() {
      this.$http.get("/product/getProduct").then((res) => {
        // console.log(res)
        this.cplbdata = res.data.data;
        // console.log(this.cplbdata[0].id)
        for (let item in this.cplbdata) {
          this.cplbdata[item].imageName =
            this.cplbdata[item].imageName.split(",")[0];
        }
      });
    },
  },
};
// import "../CSS_h5/normalize.min.css"
// import "../CSS_h5/owl.carousel.min.css"
// import "../CSS_h5/owl.theme.default.min.css"
// import "../CSS_h5/style.css"

// import "./js/owl.carousel.min.js"
// import "./js/script.js"

// export default {
//   data() {
//     return {
//       cplbdata: [],
//       gd: ["cplb0", "cplb1", "cplb2", "cplb3"],
//       carouseId: 0,
//     };
//   },
//   created() {
//     this.getcplb();
//     this.setPY();
//   },
//   methods: {
//     getcplb() {
//       this.$http.get("/product/getProduct").then((res) => {
//         // console.log(res)
//         this.cplbdata = res.data.data;
//         // console.log(this.cplbdata)
//         for (let item in this.cplbdata) {
//           this.cplbdata[item].imageName =
//             this.cplbdata[item].imageName.split(",")[0];
//         }
//       });
//     },
//     // 滑动切换
//     slideBanner() {
//       //选中的轮播图
//       var box = document.querySelector(".el-carousel__container");
//       var startPoint = 0;
//       var stopPoint = 0;
//       //重置坐标
//       var resetPoint = function () {
//         startPoint = 0;
//         stopPoint = 0;
//       };
//       //手指按下
//       box.addEventListener("touchstart", function (e) {
//         //手指点击位置的X坐标
//         startPoint = e.changedTouches[0].pageX;
//       });
//       //手指滑动
//       box.addEventListener("touchmove", function (e) {
//         //手指滑动后终点位置X的坐标
//         stopPoint = e.changedTouches[0].pageX;
//       });
//       //当手指抬起的时候，判断图片滚动离左右的距离
//       let that = this;
//       box.addEventListener("touchend", function (e) {
//         // console.log("1：" + startPoint);
//         // console.log("2：" + stopPoint);
//         if (stopPoint == 0 || startPoint - stopPoint == 0) {
//           resetPoint();
//           return;
//         }
//         if (startPoint - stopPoint > 0) {
//           resetPoint();
//           that.$refs.slideCarousel.next();
//           return;
//         }
//         if (startPoint - stopPoint < 0) {
//           resetPoint();
//           that.$refs.slideCarousel.prev();
//           return;
//         }
//       });
//     },
//     carouselChange(now, old) {
//       /* console.log(now+"，"+old); */
//       this.carouseId = now;
//       this.setPY();
//     },
//     setPY() {
//       var _carouseId = this.carouseId;
//       // console.log(_carouseId)
//       if (_carouseId == 0) {
//         $("#cplb0").css("left", "0%");
//         $("#cplb0").next().css("left", "32%");
//         $("#cplb0").next().next().css("left", "62%");
//         $("#cplb0").next().next().next().css("left", "32%");
//         // $("#cplb0").prev().css("left","-62%");
//         // $("#cplb3").prev().prev().css("left","-30%");
//       } else if (_carouseId == 1) {
//         $("#cplb1").css("left", "0%");
//         $("#cplb1").prev().css("left", "-32%");
//         $("#cplb1").next().css("left", "32%");
//         $("#cplb1").next().next().css("left", "62%");
//       } else if (_carouseId == 2) {
//         $("#cplb2").css("left", "0%");
//         $("#cplb2").prev().css("left", "-32%");
//         $("#cplb2").next().css("left", "32%");
//         $("#cplb2").prev().prev().css("left", "-32%");
//       } else {
//         $("#cplb3").css("left", "0%");
//         $("#cplb3").prev().css("left", "-32%");
//         $("#cplb3").prev().prev().css("left", "-62%");
//         $("#cplb3").prev().prev().prev().css("left", "0%");
//       }
//     },
//   },
//   mounted() {
//     //调用滑动切换方法
//     this.slideBanner();
//   },
// };
</script>

<style scoped>
.swiper-slide a {
  width: 100%;
  height: 100%;
  display: block;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.21);
  border-radius: 10px;
  overflow: hidden;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  display: block;
}
.swiper-slide {
  text-align: center;
  height: 280px;
  background: #fff;
  transition: 300ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}
.banner-box-swiper {
  width: 100%;
  height: 280px;
  overflow: hidden;
  margin: 0 auto;
}
.swiper-container {
  height: 280px;
  width: 290px;
  overflow: visible;
}
.my-container {
  /* height: 100vh; */
  width: 100%;
  /* padding-top: 12px; */
  box-sizing: border-box;
  /* margin-top: 30%; */
  margin: 20% auto;
  text-align: center;
}

/* .indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
} */
/* .indicators .item {
  width: 18px;
  height: 3px;
  border-radius: 2px;
  margin-left: 10px;
  background: #d9d9d9;
}
.active {
  background: #ff8008;
} */
</style>


<style>
/* .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.cplbzt{
    margin-top: 16%;
}
.cplbzt .block{
    left: 0 !important; width: 90%; text-align: center; margin: 0 auto;
}
.cptp_h5{
    width: 100% !important; height: inherit !important;
}
.c_lbt1_h5{
    border-radius: 100% !important;
}
.cplb0, .cplb1, .cplb2, .cplb3{
  border-radius: 20px !important;
} */
</style>