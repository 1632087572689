<template>
<div>
  <div class="heard">
    <div class="newlb_left animate__animated animate__bounceIn">
      <img src="../../assets/logo/logo-04.png" alt="">
    </div>
    <div class="newlb_right">
        <p class="newlb_w1 animate__animated animate__bounceIn">
          铸行业之<span class="newlb_w2">冠</span>
        </p>
        <p class="newlb_w3 animate__animated animate__bounceIn">
          立精英之<span class="newlb_w4">锐</span>
        </p>
    </div>
  </div>
  <div style="position: relative; padding: 3vw 0 0 7vw">
      <el-button
        class="xq_fh animate__animated animate__fadeIn"
        icon="el-icon-back"
        circle
        style="font-size: 2vw"
        @click="$router.back()"
      ></el-button>
       <!-- @click="toguid('/home','m3')" -->
      
    </div>
    <p class="testn">新闻动态</p>
    
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="top: 13.7vw; position: absolute"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
    </svg>
    <div class="test1">
      <div class="test1_h1">
        <!-- <ul v-for="item in newsdata" :key="item.id" :data="newsdata.slice((currentPage - 1) * PageSize, currentPage * PageSize)">
            <li>
              <div class="h_left" style="padding-bottom: 2vw;min-height: 10vw;">
                <router-link :to="{name: 'Details' , params:{id: item.id}}">
                
                <el-image style="width:100%;margin:5px 0 0 0" :src="item.imageName" fit="scale-down">
            <div slot="error">
              <img src="../../assets/beijing/un_img.png" style="width: 100%;"/>
            </div>
          </el-image>
                
                </router-link>
              </div>
              <div class="h_right">
                <router-link :to="{name: 'Details' , params:{id:item.id}}">
                <p class="h_right_w1">{{item.title}}</p>
                </router-link>
                <div style="height:5vw">
                  <router-link :to="{name: 'Details' , params:{id:item.id}}">
                  <p class="h_right_w2">{{item.synopsis}}</p>
                  </router-link>
                </div>
                <p class="h_right_w3">{{item.dateTime}}</p>
              </div>
            </li>
          
        </ul> -->
        
        <el-table ref='multipleTable' :show-header="false" style="width: 100%;position: relative;" :data="newsdata.slice((currentPage - 1) * PageSize, currentPage * PageSize)">
          <el-table-column prop="date" label="主图" width="180">
            <template slot-scope="scope">
            <el-image style="width:100%;height:120px;margin:15px 0 0 0;margin-top:40px;" :src="scope.row.imageName" fit="scale-down">
              <div slot="error">
                <img src="../../assets/beijing/un_img.png" style="width: 100%;height:140px;"/>
              </div>
            </el-image>
            </template>
          </el-table-column>
          <el-table-column label="内容" >
            <template slot-scope="scope">
              <router-link :to="{name: 'Details' , params:{id: scope.row.id}}">
                <p class="b_bt" :title="scope.row.title">{{ scope.row.title }}</p>
              </router-link>
              <router-link :to="{name: 'Details' , params:{id: scope.row.id}}">
                <p class="b_jj">{{ scope.row.synopsis }}</p>
              </router-link>
              <router-link :to="{name: 'Details' , params:{id: scope.row.id}}">
                <p class="b_sj">{{ scope.row.dateTime }}</p>
              </router-link>
            </template>
          </el-table-column>
    </el-table>
      <el-pagination
        style="text-align:center;margin-top:30px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, prev, pager, next"
        :total="newsdata.length"
      >
      </el-pagination>
        <div style="height:100px">
          
        </div>
      </div>
    </div>

    <div style="height: auto; background: #070d21; position: relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1 testbottom"
        style="transform: scaleY(-1); margin-top: -0.5vw"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
      <Copyright />
    </div>
     
</div>
</template>

<script>

import Copyright from "../../components/Copyright.vue";

export default {
  data() {
    return {
      newsdata:[{
          title:'',
          createtime:'',
          content:'',
          image:'',
          dateTime:'',
          imageName:''
        }],
        // currentPage:1,
        // total:'',
      currentPage: 1,
      totalCount: 1,
      pageSizes: [1, 2, 3, 4],
      PageSize: 6,
      scrollTop: '',
        
    };
  },
  

  created() {
    this.getNews();
  },
  computed: {
        
  },
  methods: {
    getNews(){
      this.$http.get('/new/getNew').then(res =>{
        // console.log(res.data.data)
        this.newsdata = res.data.data
        // console.log(this.newsdata)
        this.total = res.data.data.length
        for(let item in this.newsdata) {
          this.newsdata[item].imageName = this.newsdata[item].imageName.split(",")[0]
          this.newsdata[item].imageName = this.newsdata[item].imageName + "_s200"
        }
      })
    },
  //   toguid(path,id){
	// 	var path=path
	// 	var Id=id;
	// 	localStorage.setItem('toId',Id);
	// 	this.$router.push(path);
	// },
    handleSizeChange(val) {
        this.currentPage= val
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.currentPage= val
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        // console.log(`当前页: ${val}`);
      }
    
  },
  mounted() {},
  
  components: {
    Copyright,
  },
};
</script>

<style>
a{
  text-decoration:none;
}
.heard{
  height: 16vw;
  background: #070d21 url(../../assets/beijing/hero-glow.svg) center center no-repeat;
  /* background: #070d21 url(http://localhost:8080/img/hero-glow.9c6385ea.svg) center center no-repeat; */
}
.newlb_left{
  float: left;
}
.newlb_left img{
  width: 25vw; margin-top: 4vw;margin-left: 6vw; 
}
.newlb_right{
  margin-top: 0vw; float: right; margin-right: 10vw;
}
.newlb_w1,.newlb_w3{
  font-size: 3vw; color: #fff;font-family: 'hl';position: relative; top:3.2vw
}
.newlb_w3{
  margin-left: 4vw;
}
.newlb_w2,.newlb_w4{
  font-size: 4vw;color: orangered;margin-left: 5px;
}
.testn{
  text-align: center; font-size: 3vw; letter-spacing: 0.6vw;margin-bottom: 2vw;
}
.mbx{
    font-size: 2.2vw !important; width: 100%; padding: 3vw 0 3vw 7vw;
}
.test1{
  width: 100%;
}
.test1_h1{
  margin: 0 auto; width:75%;  -webkit-user-drag: none; 
}
.test1_h1 li{
  font-size:1.2vw;list-style:none; border-bottom: 1px dashed #c5c5c5; padding-top: 2vw; position: relative;
}
.h_left{
  width: 15%; display: inline-block; vertical-align: top; padding-right: 1vw; 
}
.h_right{
  width: 80%; display: inline-block; padding-left: 1vw;
}
.h_right_w1{
  color: #333;font-size: 1.8vw; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; letter-spacing: 0.1vw; cursor: pointer;
}
.h_right_w2:hover{
  color: #729ae4;
}
.h_right_w2{
  color: #666;
  /* height: 5vw; */
  margin-top: 0.6vw;
  font-size: 1.3vw;
  /* 多行文字省略号 */
  /* 超出内容隐藏 */
  overflow:hidden;
  /*让超出的内容用省略号显示*/
  text-overflow:ellipsis;/*作为弹性伸缩盒子模型显示*/
  display:-webkit-box;
  /*设置伸缩盒子的子元素排列方式--从上到下垂直排列*/
  -webkit-box-orient:vertical;
  /*指定显示的多少行*/
  -webkit-line-clamp:3;
  /* line-height: 2vw; */
  font-weight:lighter;
  cursor: pointer;
  /* height: 100px; */
  
}
.h_right_w3{
  color: #999; font-size: 1vw; text-align: right; right: 0; bottom: 5px; position: absolute; letter-spacing: 1px;
}
.testbottom{
  width: 100%;
  /* height: 10vw; */
}

.b_bt{
  color: #333;height: 35px;line-height: 35px;font-size: 1.8vw; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; letter-spacing: 0.1vw; cursor: pointer;
}
.b_jj{
  color: #666;margin-top: 10px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;font-weight:lighter;cursor: pointer;
}
.b_sj{
  color: #999;font-size: 1vw;position: absolute;right: 15px;bottom: 15px;
}
.cell{
  margin-top: -30px;
}
</style>
