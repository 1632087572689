<template>
    <div id="GgDuo">
        <el-button class="g_a1" type="primary" @click="dialogVisible = true">更多</el-button>

        <!-- <el-dialog class="ab_w1" title="冠锐介绍" :visible.sync="dialogVisible" width="50%">
            <span class="ab_w2">
            <span class="text1">
              福建冠锐网络技术有限公司成立于2008年，是一家专业的IT综合服务的提供商。冠锐网络致力于全方位信息化解决方案、系统集成建设及技术服务，以客户为中心，为客户提供定制的IT信息化服务。<br/>
            </span>
            <span class="text1">
              冠锐网络拥有十多年的IT服务经验，专注于网络安全、智能安防、音视频会议、虚拟化云计算、IT运维与服务等方向，合作于业界一流品牌厂商，为客户提供优秀的方案及高品质产品。<br/>
            </span>
            <span class="text1">
              冠锐网络经过多年的发展，形成了体系化的管理建设，通过了ISO/IEC9001:2015质量管理体系认证，获得了“国家高新技术企业认证”证书，拥有了管理、安全、网络、主机等方向顶尖认证的技术工程师。
            </span>
            <span class="text1">
              冠锐品牌依靠的是过硬的技术实力，多年来，受到了各行各业的用户青睐。立足于政府、金融、军队、教育、运营商、企业等行业领域，公司聚焦客户关注的业务系统综合解决方案服务能力，提供有竞争力的IT服务与解决方案，持续为客户创造更大的价值。<br/>
            </span>
            <span class="text1">
              冠锐公司是一支年轻的团队，富有激情、创造力。“铸行业之冠，立精英之锐”是冠锐公司的核心发展理念。“学无止境”是公司的文化素养。“真诚至上”是公司服务客户的核心理念。<br/>
            </span>
            <span class="text1">
              冠锐公司服务于社会，取之于社会，将一直秉承感恩的心，服务于客户；保持一颗初心，匠心、恒心，与客户携手共同为社会创造更大的价值。
            </span>

            </span>
          
        </el-dialog> -->
    </div>  
</template>

<script>
export default {
    name:'GgDuo',
    data() {
      return {
        dialogVisible:false
      }
    },
    methods: {
      // open() {
      //   this.$alert('<strong>这是 <i>HTML</i> 片段</strong>', '关于冠锐', {
      //     dangerouslyUseHTMLString: true
      //   });
      // }
    }
}
</script>
<style scoped>

.el-dialog__title{
  font-size: 25px !important;
}
.ab_w2{
  line-height: 30px;
  text-align: left;
  display:block;
  margin-top: -25px;
  font-size: 15px;
  letter-spacing: 3px;
  /* 首行缩进2字符 */
  /* text-indent:2em; */
}
.text1{
  display:block;
  text-indent:2em;
}
</style>