<template>
  <div>
        <el-button class="ng" type="button" @click="dialogTableVisibleng = true">更多</el-button>
        <!-- 弹出表格 -->
        
  </div>
</template>

<script>
export default {
    data() {
      return {
        
        count: 10,
        loading: false,
        dialogTableVisibleng: false,
        dialogFormVisible: false,
        formLabelWidth: '120px'
      };
    },
    created() {
    this.getNews();
  },
  methods: {
    getNews(){
      this.$http.get('/new/getNew').then(res =>{
        // console.log(res)
        this.newsdata = res.data.data
        // console.log(this.newsdata)
      })
    }
  },
  
}
</script>

<style>
.n_b1{
  margin-top: -25px;
}
.ng{
  background: linear-gradient(#4ba0f1,#2965ed) !important;
    font-size: 15px !important;
    color: #fff !important;
    border: 0 !important;
    width: 10vw !important;
    height: 2vw !important;
    font-size: 1vw !important ;
    line-height: 25% !important;
    border-radius: 100px !important;
    /* height: 35px !important; */
}
</style>