var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"announce_header"},[_vm._m(0),_c('div',{staticClass:"announce_header_right"},[_c('div',{staticClass:"announce_header_right_btnone"},[(this.userInformation.username != undefined &&
                    this.userInformation.username != '' &&
                    this.userInformation.username != null
                    )?_c('span',{staticStyle:{"color":"white"}},[_vm._v("欢迎回来，"+_vm._s(this.userInformation.username))]):_vm._e(),(this.userInformation.username != undefined &&
                    this.userInformation.username != '' &&
                    this.userInformation.username != null
                    )?_c('span',[_c('el-button',{staticStyle:{"padding":"0 15px","border-radius":"50px","margin-right":"15px","margin-left":"15px","color":"white"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.seethatperson()}}},[_vm._v("个人中心")]),_c('el-button',{staticStyle:{"padding":"0 15px","border-radius":"50px"},attrs:{"type":"danger"},on:{"click":function($event){return _vm.Logout('退出登录')}}},[_vm._v("退出")])],1):_vm._e()])])]),_c('div',{staticClass:"banner_centan"},[_c('el-card',{staticClass:"box-card"},[_c('p',[_vm._v("项目名称："+_vm._s(_vm.modelasdasdad.title))]),_c('p',{staticStyle:{"margin-top":"10px"}},[_vm._v("投标公司："+_vm._s(_vm.modelasdasdad.company))]),_c('p',{staticStyle:{"margin-top":"10px"}},[_vm._v("联系人员："+_vm._s(_vm.modelasdasdad.contacts))]),_c('p',{staticStyle:{"margin-top":"10px"}},[_vm._v("联系电话："+_vm._s(_vm.modelasdasdad.tel))]),_c('p',{staticStyle:{"margin-top":"10px","margin-bottom":"20px"}},[_vm._v("支付用途：作为投标人参与招标的一种担保，确保投标人的诚意和真实意愿")]),_c('p',{staticStyle:{"text-align":"right"}},[_vm._v("金额："),_c('span',{staticStyle:{"color":"red","font-size":"20px","font-weight":"bold"}},[_vm._v("￥ "),_c('span',{staticStyle:{"font-size":"32px"}},[_vm._v("100.00")])])]),_c('p',{staticStyle:{"text-align":"right","margin-top":"10px"}},[_c('el-button',{staticStyle:{"width":"150px","height":"50px"},attrs:{"type":"success"},on:{"click":_vm.btnFun}},[_vm._v("微信支付")])],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"announce_header_left"},[_c('p',{staticClass:"announce_header_left_h1"},[_vm._v("待支付")])])
}]

export { render, staticRenderFns }