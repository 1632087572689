<template>
  <div class="cplbzt">
    <div class="block">
      <el-carousel :interval="5000" type="card" @change="carouselChange" ref="slideCarousel" height="230px" indicator-position="none" arrow="never">
        <el-carousel-item :id="gd[index]" :class="gd[index]" v-for="(item, index) in allbdata.slice(0, 4)" :key="index" >
          <router-link style="float:left;" :to="{name:'detal_h5',params:{id: allbdata[index].id}}">
          <el-image class="cptp_h5" fit="fill" :src="item.imageName" alt=""></el-image>
          </router-link>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      allbdata: [],
      gd: ["cplb0", "cplb1", "cplb2", "cplb3"],
      carouseId: 0,
    }
  },
  created() {
    this.getallb();
  },
  computed: {},
  mounted() {
    this.slideBanner()
  },
  methods: {
    // 轮播图
    getallb() {
      this.$http.get("/cases/getCases").then((res) => {
        // console.log(res)
        this.allbdata = res.data.data;
        // console.log(this.cplbdata)
        for (let item in this.allbdata) {
          this.allbdata[item].imageName =
            this.allbdata[item].imageName.split(",")[0];
        }
      });
    },
    // 滑动切换
	slideBanner() {
	  //选中的轮播图
	  var box = document.querySelector('.el-carousel__container');
	  var startPoint = 0;
	  var stopPoint = 0;
	  //重置坐标
	  var resetPoint = function () {
	    startPoint = 0;
	    stopPoint = 0;
	  }
	  //手指按下
	  box.addEventListener("touchstart", function (e) {
	    //手指点击位置的X坐标
	    startPoint = e.changedTouches[0].pageX;
	  });
	  //手指滑动
	  box.addEventListener("touchmove", function (e) {
	    //手指滑动后终点位置X的坐标
	    stopPoint = e.changedTouches[0].pageX;
	  });
	  //当手指抬起的时候，判断图片滚动离左右的距离
	  let that = this
	  box.addEventListener("touchend", function (e) {
	    // console.log("1：" + startPoint);
	    // console.log("2：" + stopPoint);
	    if (stopPoint == 0 || startPoint - stopPoint == 0) {
	      resetPoint();
	      return;
	    }
	    if (startPoint - stopPoint > 0) {
	      resetPoint();
	      that.$refs.slideCarousel.next();
	      return;
	    }
	    if (startPoint - stopPoint < 0) {
	      resetPoint();
	      that.$refs.slideCarousel.prev();
	      return;
	    }
	  });
	},
  },
};
</script>

<style>
  .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.cplbzt{
    margin-top: 16%;
}
.cplbzt .block{
  left: 0 !important; width: 90%; text-align: center; margin: 0 auto;
}
.cptp_h5{
  width: 100% !important; height: inherit !important;
}
.c_lbt1_h5{
  border-radius: 100% !important;
}
.cplb0, .cplb1, .cplb2, .cplb3{
  border-radius: 20px !important; height: 250px;
}
</style>

