<template>
  <div id="Friends">
    <p class="fr_title">合作伙伴</p>
    <p class="fr_title1">cooperative partner</p>
    <!-- <ul class="friends_ul">
      <li v-for="(item, index) in friends" :key="index">
        {{item.name}}
      </li>
    </ul> -->
    <div class="div_content">
      <div class="mine">冠锐</div>
      <ul class="friends_ul">
        <li v-for="(item, index) in friends" :key="index">
          {{item.name}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Friends",
  data() {
    return {
      friends: [
        { name: '福建省气象局'},
        { name: '福州海关'},
        { name: '福建省铁通'},
        { name: '福建省民政厅'},
        { name: '宁德新能源科技有限公司'},
        { name: '杭州帕拉迪网络科技有限公司'},
      ]
    }
  },
  methods: {},
};
</script>
<style scoped>
.fr_title{text-align: center; font-size: 46px; width: 100%; margin-top: 50px; color: #333;}
.fr_title1{text-align: center; font-size: 16px; width: 100%; margin-top: 10px; color: #729ae4;letter-spacing:1px;}
.div_content{display: inline-block; width: 100%; font-weight: lighter; margin-top: 50px;}
.friends_ul{width: 65%; margin-top: 50px; text-align: center; float: left;}
.friends_ul li{display: inline-block; font-size: 17px; width: calc(50% - 60px); color: #333; padding: 0 30px; height: 50px; line-height: 50px; text-align: left; float: left;}
.mine{width: calc(35% - 200px); display: inline-block; float: left; font-weight: bold; padding: 60px 0 0 200px; font-size: 24px;}
</style>