<template>
<div>
  <div class="dao_hz_h5" ref="pronbit" :style="gdgd > 100 ? 'background-color:#fff;' : 'background-color:transparent;'">
    <img
     v-show="test1_t_h5"
      class="dao_tp_h5"
      src="../home/images/dao_img_h5.png"
      @click="drawer = true"
      alt=""
    />
    <img
     v-show="test2_t_h5"
      class="dao_tp_h5"
      src="../home/images/hei.png"
      @click="drawer = true"
      alt=""
    />
    <img class="tp1_h5" src="http://qigugu.ifchus.com/logo-04.png" alt="" />
  </div>
  <el-drawer
            title="我是标题"
            :visible.sync="drawer"
            :modal="false"
            direction="ltr"
            class="m1_daohang_h5"
            :with-header="false">
            <div style="width:100%; text-align: center;">
              <img class="dao_d_t_h5" src="../home/images/dt.png" alt="">
            </div>
            
                <ul class="cbl_h5">
                    <li onclick="javascript:document.getElementById('m1_h5').scrollIntoView({behavior:'smooth'})" @click="drawer = false">
                      <i class="test_1_h5 el-icon-house" @click="drawer = false"><span class="test_1_s_h5">首页</span></i>
                    </li>
                    <li onclick="javascript:document.getElementById('m2_h5').scrollIntoView({behavior:'smooth'})" @click="drawer = false">
                      <i class="test_1_h5 el-icon-copy-document" @click="drawer = false"><span class="test_1_s_h5">关于冠锐</span></i>
                      </li>
                    <li onclick="javascript:document.getElementById('m3_h5').scrollIntoView({behavior:'smooth'})" @click="drawer = false">
                      <i class="test_1_h5 el-icon-notebook-1" @click="drawer = false"><span class="test_1_s_h5">新闻动态</span></i>
                      <el-badge class="mark123" :value="xw_zs_h5" />
                      </li>
                    <li onclick="javascript:document.getElementById('m4_h5').scrollIntoView({behavior:'smooth'})" @click="drawer = false">
                      <i class="test_1_h5 el-icon-takeaway-box" @click="drawer = false"><span class="test_1_s_h5">产品展示</span></i>
                      <el-badge class="mark123" :value="cp_zs_h5" />
                      </li>
                    <li onclick="javascript:document.getElementById('m5_h5').scrollIntoView({behavior:'smooth'})" @click="drawer = false">
                      <i class="test_1_h5 el-icon-tickets" @click="drawer = false"><span class="test_1_s_h5">成功案例</span></i>
                      <el-badge class="mark123" :value="al_zs_h5" />
                      </li>
                    <li onclick="javascript:document.getElementById('m6_h5').scrollIntoView({behavior:'smooth'})" @click="drawer = false">
                      <i class="test_1_h5 el-icon-chat-line-round" @click="drawer = false"><span class="test_1_s_h5">联系我们</span></i>
                      </li>
                </ul>
    </el-drawer>
</div>
</template>

<script>

// import smoothscroll from 'smoothscroll-polyfill'
export default {
    data() {
        return {
            drawer: false,
            gdgd:'',
            test1_t_h5:true,
            test2_t_h5:false,
            xw_zs_h5:'',
            cp_zs_h5:'',
            al_zs_h5:''
        }
    },
    created() {
      this.getqb_h5()
    },
    mounted(){
      window.addEventListener('scroll',this.handleScrollx,true)
      // smoothscroll.polyfill()
      },
      methods: {
        handleScrollx() {
          // console.log('滚动高度',window.pageYOffset)
          this.gdgd = window.pageYOffset
          // console.log(this.gdgd)
          // console.log('距离顶部高度',this.$refs.pronbit.getBoundingClientRect().top)
          if(this.gdgd > 100) {
            // console.log("进入判断",this.gdgd)
            this.test1_t_h5 = false,
            this.test2_t_h5 = true
          }else{
            this.test1_t_h5 = true,
            this.test2_t_h5 = false
          }
        },
        getqb_h5() {
          // 新闻总数
          this.$http.get("/new/getNew").then((res) => {
            this.xw_zs_h5 = res.data.data.length
            // console.log(this.xw_zs_h5)
          });
          // 产品总数
          this.$http.get("/product/getProduct").then((res) => {
            this.cp_zs_h5 = res.data.data.length
            // console.log(this.cp_zs_h5)
          });
          this.$http.get("/cases/getCases").then((res) => {
            this.al_zs_h5 = res.data.data.length
            // console.log(this.al_zs_h5)
          });
        }
    }
}
</script>

<style>
.dao_d_t_h5{
  width: 60px; margin-top: 50px; color: #333; font-size: 6vw; margin-bottom: 15px;
}
.mark123{
  width: 20px; margin-left: 30px; float: left; height: 18px;
}
.mark123 .el-badge__content {
  color: #fff !important; font-size: 14px !important; padding: 0 10px !important; height: 18px !important; line-height: 18px !important; display:inline-block !important;
  border: 0px !important; margin-top: 1px;
}
.test_1_h5{
  vertical-align: middle;margin-left: 30px; margin-right: 15px; float: left; height: 25px; font-size: 16px; margin-top: 1px;
}
.test_1_s_h5{
  height: 25px; padding-left:15px
}
.cbl_h5{
}
</style>