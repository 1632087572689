<template>
    <el-carousel :interval="3000" type="card" height="20vw">
        <el-carousel-item  v-for="item in anlidata.slice(0,5)" :key="item.index">
            <el-image class="zmdtp"
                @click="dj(item)" 
                fit="cover"
                :src="item.imageName" 
                :preview-src-list="srcList">
            </el-image>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
const axios = require('axios')
export default {
    data() {
        return {
            anlidata:[],
            srcList:[]
        }
    },
    created() {
    this.getCases();
    },
    methods: {
        getCases(){
            this.$http.get('/cases/getCases').then(res =>{
            // console.log(res)
            this.anlidata = res.data.data
            for(let item in this.anlidata) {
          this.anlidata[item].imageName = this.anlidata[item].imageName.split(",")[0]
        }
            // console.log(this.anlidata)
      })
        },
        dj(item){
            this.srcList = []
            // console.log(item.imageName)
            this.srcList.push(item.imageName)
        }
    },
}
</script>

<style>
.block{
    left: calc(30%);
}
.el-carousel__button{
    width: 3vw !important;
    height: 0.2vw !important;
    background-color: rgb(255, 255, 255) !important;
    margin-top: 1vw !important;
    margin-left: 10px;
    margin-right: 10px;
  }
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  .zmdtp{
  width: 100% !important;
  /* margin-top: -25px !important; */
}
  .el-carousel__item{
      height: none !important;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .l_tp{
      width: 100%;
      height: 100%;
  }
  .el-image-viewer__img{
      margin-top: 0 !important;
      width: 60vw;
  }
  .el-image-viewer__canvas{
      height: 75% !important;
      margin-top: 100px !important;
  }
  .el-image-viewer__close{
      width: 2.5vw !important;
      height: 2.5vw !important;
      font-size: 1.8vw !important;
  } 
</style>