<template>
<!-- 下载依赖失败的时候可以把路由重新下载 -->
  <div id="app">
    <!-- 首页 -->
    <div id="m1" class="m1 scrollSnap" style="height: 722px">
      <!-- 导航栏 -->
      <div class="navigation">
        <Navigation />
      </div>
      <div class="s_h1">
        <p class="s_w1 animate__animated animate__fadeInLeftBig">
          铸行业之<span class="s_w2">冠</span>
        </p>
        <p class="s_w3 animate__animated animate__fadeInLeftBig">
          立精英之<span class="s_w4">锐</span>
        </p>
        <p class="s_w5 animate__animated animate__fadeInRight">
          GIVE YOU INFINITE POSSIBILITIES AND HOPE
        </p>
      </div>
      <!-- <div id="main" class="s_h2" style="width: 50vw;height:65vh">
      </div> -->
      <!-- <div class="dhtp">
        <Iconmenu />
      </div> -->
      <div class="container container_pc_ys">
        <div class="wrap">
          <div class="cube">
            <div class="out-front">
              <img src="./images/t1.png" alt="" />
            </div>
            <div class="out-back">
              <img src="./images/t2.png" alt="" />
            </div>
            <div class="out-left">
              <img src="./images/t3.png" alt="" />
            </div>
            <div class="out-right">
              <img src="./images/t4.png" alt="" />
            </div>
            <div class="out-top">
              <img src="./images/t5.png" alt="" />
            </div>
            <div class="out-bottom">
              <img src="./images/t6.png" alt="" />
            </div>

            <span class="in-front">
              <img src="./images/t7.png" alt="" />
            </span>
            <span class="in-back">
              <img src="./images/t8.png" alt="" />
            </span>
            <span class="in-left">
              <img src="./images/t1.png" alt="" />
            </span>
            <span class="in-right">
              <img src="./images/t2.png" alt="" />
            </span>
            <span class="in-top">
              <img src="./images/t3.png" alt="" />
            </span>
            <span class="in-bottom">
              <img src="./images/t4.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 关于冠锐 -->
    <div id="m2" class="m2 scrollSnap" style="height: 722px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        class="position-absolute width-full z-1"
        style="top: -2vw; position: absolute"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>

      <p class="g_w1">关于<span class="g_w2">冠锐</span></p>
      <p class="g_w3">WELCOME TO THE FUTURE</p>
      <!-- <img class="g_t1" :src="abdata.imageName" alt="" /> -->
      <div class="demo" style="min-height:150px;margin-top:6vw">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loader">
                      <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                      <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                      <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                      <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <!-- <div class="div_banner">
        <el-carousel trigger="click" style="border-radius: 3px">
          <el-carousel-item v-for="(item, index) in banners" :key="index">
            <el-image :src="item.url" fit="cover"> </el-image>
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <div class="g_div">
        <div>
          <p class="g_w4">
            {{ abdata.str1 }}
          </p>
          <p class="g_w5">
            {{ abdata.str2 }}
          </p>
        </div>

        <div style="margin-top: 3%">
          <router-link to="/About">
            <el-button class="g_an" type="primary" round>➜</el-button>
          </router-link>
        </div>
      </div>
      <!-- <div style="position: relative;text-align: center;top:65%">
        </div> -->
    </div>

    <div class="zjdhhz">
      <ul class="zjdh">
        <li @click="ystj('lbl1', 'm1')">
          <label id="lbl1" class="js_item css_item">首页</label>
        </li>
        <li @click="ystj('lbl2', 'm2')">
          <label id="lbl2" class="js_item">关于冠锐</label>
        </li>
        <li @click="ystj('lbl3', 'm3')">
          <label id="lbl3" class="js_item">新闻动态</label>
        </li>
        <li @click="ystj('lbl4', 'm4')">
          <label id="lbl4" class="js_item">产品展示</label>
        </li>
        <li @click="ystj('lbl5', 'm5')">
          <label id="lbl5" class="js_item">成功案例</label>
        </li>
        <li @click="ystj('lbl6', 'm6')">
          <label id="lbl6" class="js_item">联系我们</label>
        </li>
      </ul>
    </div>

    <div class="div_cont">
      <!-- 新闻动态 -->
      <div id="m3" class="m3 scrollSnap">
        <p class="n_w1">新闻<span class="n_w2">动态</span></p>
        <p class="n_w3">WELCOME TO THE FUTURE</p>
        <div class="n_z1">
          <Time />
        </div>
        <router-link to="/MoreNews">
          <div class="ng1">
            <NgDuo />
          </div>
        </router-link>
      </div>

      <!-- 产品展示 -->
      <div id="m4" class="m4 scrollSnap">
        <p class="icon_arrow"></p>
        <p class="c_w1">产品<span class="c_w2">展示</span></p>
        <p class="c_w3">PRODUCT SHOW</p>
        <!-- 展示卡 for循环-->
        <div style="width: 100%; text-align: center; margin-top: 5%">
          <div
            :class="sstt[index]"
            v-for="(item, index) in productdata.slice(0, 4)"
            :key="index"
            @click="test([index])"
          >
            <el-image
              class="cptp1"
              style="width: 40vw; max-height: 40vw"
              :src="productdata[index].imageName"
              fit="cover"
            >
            </el-image>

            <!-- <img class="c_t1" :src="productdata[index].imageName" alt=""> -->
            <p class="c_w4">{{ productdata[index].title }}</p>
            <p class="c_w5" >{{ productdata[index].synopsis }}</p>
          </div>
        </div>
        <div style="width: 100%; text-align: center; padding-bottom: 6%">
          <router-link to="/MoreProducts">
            <el-button class="ng" type="primary" round style="margin-top: 5%"
              >更多</el-button
            >
          </router-link>
        </div>
      </div>

      <!-- 成功案例 -->
      <div id="m5" class="m5 scrollSnap" style="height: 780px">
        <p class="icon_arrow"></p>
        <p class="n_w1">成功<span class="n_w2">案例</span></p>
        <p class="n_w3">SUCCESSFUL CASE</p>
        <div class="lun">
          <Carousel />
          <div style="width: 100%; text-align: center;">
            <router-link to="/MoreSuccess">
              <el-button class="ng" type="primary" round style="margin-top: 5%; position: relative; z-index: 10;"
                >更多</el-button
              >
            </router-link>
          </div>
        </div>
      </div>

      <!-- 联系我们 -->
      <div id="m6" class="m6 scrollSnap" style="height: 780px">
        <p class="icon_arrow" style="padding-bottom: 2%"></p>
        <p class="w_w1">联系我们</p>
        <p class="w_w2">CONTACT US</p>
        <img class="w_tp" src="http://qigugu.ifchus.com/icon_str3.png" alt="" />
        <img class="w_tp1 content ttt" src="../../assets/beijing/icon_str3ball.png" alt="" />
        <!-- <div class="w_h1"></div> -->
        <div class="w_h2">
          <p class="w_w3" style="white-space: pre-wrap;" v-html="liandata.str">
          </p>
        </div>
      </div>
    </div>

    <div style="height: auto; padding: 20px 0 50px 0">
      <Friends />
    </div>

    <div style="height: auto; background: #070d21; position: relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="transform: scaleY(-1); margin-top: -0.5vw"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
      <Copyright />
    </div>

    <!-- 产品展示的弹窗 -->
    <el-dialog class="zs_w1" :visible.sync="dialogVisible" width="90vw">
      <div class="tkhz">
        <div style="text-align: center">
          <p style="font-size: 2vw; color: #333">{{ cp.title }}</p>
          <p style="font-size: 1.6vw; margin: 2% 0 0 0; color: #999">
            {{ cp.createtime }}
          </p>
        </div>
        <div style="text-align: center; margin: 5% 0 4% 0">
          <el-image
            class="cptp2"
            style="width: 40vw; max-height: 40vw"
            :src="cp.imageName"
            fit="cover"
          >
          </el-image>
        </div>
        <div class="cptp3" style="text-indent: 2vw; color: #666; padding: 2%" v-html="Prdatas">
          <!-- <span class="zs_w2">{{ cp.content }}</span> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Navigation from "../../components/Navigation.vue";
import GgDuo from "../../components/GgDuo.vue";
import Time from "../../components/Time.vue";
import Carousel from "../../components/Carousel.vue";
import NgDuo from "../../components/NgDuo.vue";
import Iconmenu from "../../components/Iconmenu.vue";
import Friends from "../../components/Friends.vue";
import Copyright from "../../components/Copyright.vue";

// import echarts from "echarts";
// import "echarts-gl";
// import "echarts/map/js/world.js";
// import whhh from "../../../public/flights.json";

import "@/css/m1.css";
import "@/css/m2.css";
import "@/css/m3.css";
import "@/css/m4.css";
import "@/css/m6.css";
import "animate.css";
// import "@/css/block3d.css";
import "@/css/fk_pc.css";

import $ from "jquery";

export default {
  name: "App",
  data() {
    return {
      Prdatas:[],
      Prdataes:[],
      dialogVisible: false,
      aboutdata: [],
      abdata: [],
      productdata: [
        { id: "", content: "", title: "", imageName: "" },
        { id: "", content: "", title: "", imageName: "" },
        { id: "", content: "", title: "", imageName: "" },
        { id: "", content: "", title: "", imageName: "" },
      ],
      liandata: [],
      anlidata: [],
      cp: [],
      sstt: ["c_z1", "c_z2", "c_z3", "c_z4"],
      // baseTexture: require("../../assets/t2.jpg"),
      // heightTexture: require("../../assets/t1.jpg"),
      banners: [
        { url: require("../../assets/beijing/banner1.jpg") },
        { url: require("../../assets/beijing/banner2.jpg") },
        { url: require("../../assets/beijing/banner3.jpg") },
        { url: require("../../assets/beijing/banner4.jpg") },
      ],
    };
  },
  created() {
    this.getabout();
    this.set3d();
  },
  methods: {
    set3d() {
      //获取默认尺寸
      var outWidth = $(" .container .wrap .cube div").width();
      var inWidth = $(" .container .wrap .cube span").width();
      var inOffset = 0.25 * outWidth;
      var outOffset = 0.5 * outWidth;
      //修改尺寸  okk但offset还要改
      function set(outWidth, inWidth) {
        inWidth = inWidth || 0.5 * outWidth;
        $(" .container .wrap .cube div").width(outWidth);
        $(" .container .wrap .cube span").width(inWidth);
      }
    },
    ystj(item, id) {
      for (let i in document.getElementsByClassName("js_item")) {
        if (
          document.getElementsByClassName("js_item")[i].className ==
          "js_item css_item"
        ) {
          document
            .getElementsByClassName("js_item")
            [i].classList.remove("css_item");
        }
      }
      // console.log('1111', document.getElementById(item))
      setTimeout(() => {
        document.getElementById(item).classList.add("css_item");
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    getabout() {
      // 查询关于冠锐
      this.$http.get("/introduction/getIntroduction").then((res) => {
        // console.log(res)
        this.abdata = res.data.data[0]
        // console.log(this.abdata)
      });
      // 查询产品展示
      this.$http.get("/product/getProduct").then((res) => {
        // console.log(res)
        this.productdata = res.data.data
        
        // console.log(this.productdata)
        for(let item in this.productdata) {
          this.productdata[item].imageName = this.productdata[item].imageName.split(",")[0]
        }
      });
      // 查询联系
      this.$http.get("/contact/getContact").then((res) => {
        // console.log(res)
        this.liandata = res.data.data;
        // console.log(this.liandata)
      });
      // 查询案例
      this.$http.get("/cases/getCases").then((res) => {
        // console.log("1",res)
        this.anlidata = res.data.data;
        // console.log("2",this.anlidata)
        for(let item in this.anlidata) {
          this.anlidata[item].imageName = this.anlidata[item].imageName.split(",")[0]
        }
      });
      this.$http.get("/product/getContent", { params: { id: this.cp.id } }).then((res) => {
            // console.log(res)
            this.Prdatas = res.data.message;
            // console.log(this.Prdatas)
            })
    },
    // 将产品的值传入弹窗内
    test(size) {
      // console.log(size)
      this.cp = this.productdata[size];
      this.$http.get("/product/getContent", { params: { id: this.cp.id } }).then((res) => {
            // console.log(res)
            this.Prdatas = res.data.message;
            // console.log(this.Prdatas)
            })
      // console.log(this.cp)
      this.dialogVisible = true;
    },

    //     drawChart(){
    //       // var ROOT_PATH = 'http://echarts.apache.org/examples';
    //       var chartDom = document.getElementById('main');
    //       var myChart = echarts.init(chartDom);
    //       // var myChart = this.$echarts.init(document.getElementById('main'))
    //       var option;

    //       function getAirportCoord(idx) {
    //         return [whhh.airports[idx][3], whhh.airports[idx][4]];
    //     }
    //       var routes = whhh.routes.map(function (airline) {
    //         return [getAirportCoord(airline[1]), getAirportCoord(airline[2])];
    //     });
    //       myChart.setOption({
    //         // backgroundColor: '#000',
    //         globe: {
    //           baseTexture:this.baseTexture,
    //           heightTexture:this.heightTexture,
    //           shading: 'lambert',
    //           light: {
    //             ambient: {
    //               intensity: 0.4
    //             },
    //             main: {
    //               intensity: 0.4
    //             }
    //           },
    //           viewControl: {
    //             autoRotate: true, // 是否开启视角绕物体的自动旋转查看
    //             autoRotateSpeed: 3, //物体自转的速度,单位为角度 / 秒，默认为10 ，也就是36秒转一圈。
    //             autoRotateAfterStill: 2, // 在鼠标静止操作后恢复自动旋转的时间间隔,默认 3s
    //             rotateSensitivity: 2, // 旋转操作的灵敏度，值越大越灵敏.设置为0后无法旋转。[1, 0]只能横向旋转.[0, 1]只能纵向旋转
    //             targetCoord: [116.46, 39.92], // 定位到北京
    //              maxDistance: 200,
    //                 minDistance: 200
    //           }
    //         },
    //     series: {
    //       type: 'lines3D',
    //       coordinateSystem: 'globe',
    //       blendMode: 'lighter',
    //       lineStyle: {
    //         width: 1,
    //         color: 'rgb(123, 50, 150)',
    //         opacity: 0.1
    //       },
    //       data: routes
    //     }
    //   });

    // option && myChart.setOption(option);
    //     },
  },
  mounted() {
    //页面刷新回到顶部
    window.addEventListener("beforeunload", (e) => {
      window.scroll(0, 0);
    });
    // this.drawChart();
  },
  // 文字收缩
  filters: {
    // 拦截器，超过50个字，多的进行隐藏
    ellipsis(value) {
      if (!value) return ""; //如果没有返回空
      if (value.length > 10) {
        return value.slice(0, 50) + "..."; //长度大于10的后面用......代替
      }
      return value;
    },
  },
  components: {
    Navigation,
    GgDuo,
    Time,
    Carousel,
    NgDuo,
    Iconmenu,
    Friends,
    Copyright,
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
  -webkit-user-select: none;
  padding-right: 0px !important;
}
.app {
  z-index: 1;
}
img {
  -webkit-user-drag: none;
}
.lun {
  padding: 0vw 12.2vw 0vw 12.2vw;
  position: absolute;
  width: 75%;
  top: 40%;
  transform: translate(0%, 0px) translateZ(0px);
}

.dhtp {
  position: fixed !important;
  right: 2%;
  bottom: 2%;
  z-index: 10;
}
canvas {
  width: 50vw !important;
  height: auto !important;
}
</style>
<style>
/* 全模块定位和总布局 用定位top/left,没有margin但不脱标调位置 */

.container {
  position: absolute;
  top: 22vw;
  right: 22vw;
}
/* 主要用来装功能块在容器内保证定位 相当于该功能块的padding*/

.container .wrap {
  width: 100px;
  height: 100px;
  top: 150px;
  left: 150px;
  margin: 0;
}
/* 功能块 */

.container .wrap .cube {
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  /* perspective: 500px; */
  transform: rotateX(-30deg) rotateY(-80deg);
  animation: ziZhuan linear 20s infinite;
  animation-direction: alternate-reverse;
  transition: all 0.5s;
}

@keyframes ziZhuan {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

.container .wrap .cube div {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: rgb(0, 183, 255);
  z-index: -1;
  transition: all 0.5s;
  opacity: 0.45;
  outline: rgb(40, 226, 240) solid thick;
  box-shadow: rgba(17, 123, 194, 0.712) 0 0 50px 50px;
}

.container .wrap .cube div img {
  width: 100%;
  height: 100%;
}

.container .wrap .cube span {
  position: absolute;
  top: 50px;
  left: 50px;
  display: block;
  width: 100px;
  height: 100px;
  outline: rgba(21, 40, 211, 0.664) solid thin;
  background-color: rgba(11, 96, 224, 0.295);
}

.container .wrap .cube span img {
  width: 100%;
  height: 100%;
}

.container .wrap .cube .out-front {
  transform: rotateY(0deg) translateZ(100px);
}

.container .wrap .cube .out-back {
  transform: translateZ(-100px);
}
/* //y正方向逆时针 */

.container .wrap .cube .out-left {
  transform: rotateY(-90deg) translateZ(100px);
}

.container .wrap .cube .out-right {
  transform: rotateY(90deg) translateZ(100px);
}
/*  x->正-上*/

.container .wrap .cube .out-top {
  transform: rotateX(90deg) translateZ(100px);
}

.container .wrap .cube .out-bottom {
  background-color: rgba(36, 238, 80, 0.253);
  transform: rotateX(-90deg) translateZ(100px);
}

.container .wrap .cube .in-left {
  transform: rotateY(-90deg) translateZ(50px);
}

.container .wrap .cube .in-right {
  transform: rotateY(90deg) translateZ(50px);
}

.container .wrap .cube .in-back {
  transform: translateZ(-50px);
}

.container .wrap .cube .in-front {
  transform: translateZ(50px);
}

.container .wrap .cube .in-top {
  transform: rotateX(90deg) translateZ(50px);
}

.container .wrap .cube .in-bottom {
  transform: rotateX(-90deg) translateZ(50px);
}

.container .wrap .cube:hover .out-front {
  transform: rotateY(0deg) translateZ(200px);
}

.container .wrap .cube:hover .out-back {
  transform: rotateY(0deg) translateZ(-200px);
}

.container .wrap .cube:hover .out-top {
  transform: rotateX(90deg) translateZ(200px);
}

.container .wrap .cube:hover .out-bottom {
  transform: rotateX(-90deg) translateZ(200px);
}

.container .wrap .cube:hover .out-right {
  transform: rotateY(90deg) translateZ(200px);
}

.container .wrap .cube:hover .out-left {
  transform: rotateY(-90deg) translateZ(200px);
}
</style>