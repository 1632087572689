<template>
    <div>
        <div class="heard">
      <div class="newlb_left animate__animated animate__bounceIn">
        <img src="../../assets/logo/logo-04.png" alt="" />
      </div>
      <div class="newlb_right">
        <p class="newlb_w1 animate__animated animate__bounceIn">
          铸行业之<span class="newlb_w2">冠</span>
        </p>
        <p class="newlb_w3 animate__animated animate__bounceIn">
          立精英之<span class="newlb_w4">锐</span>
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="margin-top: 4.38%"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
        </div>
        <div style="position: relative; padding: 3vw 0 0 7vw">
      <el-button
        class="xq_fh animate__animated animate__fadeIn"
        icon="el-icon-back"
        circle
        style="font-size: 2vw"
        @click="$router.back()"
      ></el-button>
       <!-- @click="toguid('/home','m3')" -->
      <p class="pr_lb_bt">成功案例</p>
    </div>

        <div class="su_lb_zj">
            <div class="su_lb_zj_h1">
        <!-- <ul v-for="item in successdata" :key="item.id">
            <li>
              <div class="h_left" style="padding-bottom: 2vw;">
                <router-link :to="{name: 'SuDetails' , params:{id: item.id}}">
                <el-image style="width:100%; height: 10vw;" :src="item.imageName" fit="scale-down"></el-image>
                </router-link>
              </div>
              <div class="h_right" @click="details([index])" >
                <router-link :to="{name: 'SuDetails' , params:{id:item.id}}">
                <p class="h_right_w1">{{item.title}}</p>
                </router-link>
                <div style="height:5vw">
                  <router-link :to="{name: 'SuDetails' , params:{id:item.id}}">
                  <p class="h_right_w2">{{item.synopsis}}</p>
                  </router-link>
                </div>
                <p class="h_right_w3">{{item.dateTime}}</p>
              </div>
            </li>
          
        </ul> -->
         <el-table ref='multipleTable' :show-header="false" style="width: 100%;position: relative;" :data="successdata.slice((currentPage - 1) * PageSize, currentPage * PageSize)">
          <el-table-column prop="date" label="主图" width="180">
            <template slot-scope="scope">
            <el-image style="width:100%;height:120px;margin:15px 0 0 0;margin-top:40px;" :src="scope.row.imageName" fit="scale-down">
              <div slot="error">
                <img src="../../assets/beijing/un_img.png" style="width: 100%;"/>
              </div>
            </el-image>
            </template>
          </el-table-column>
          <el-table-column label="内容" >
            <template slot-scope="scope">
              <router-link :to="{name: 'SuDetails' , params:{id: scope.row.id}}">
                <p class="b_bt" :title="scope.row.title">{{ scope.row.title }}</p>
              </router-link>
              <router-link :to="{name: 'SuDetails' , params:{id: scope.row.id}}">
                <p class="b_jj">{{ scope.row.synopsis }}</p>
              </router-link>
              <router-link :to="{name: 'SuDetails' , params:{id: scope.row.id}}">
                <p class="b_sj">{{ scope.row.dateTime }}</p>
              </router-link>
            </template>
          </el-table-column>
    </el-table>
      <el-pagination
        style="text-align:center;margin-top:30px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, prev, pager, next"
        :total="successdata.length"
      >
      </el-pagination>
        <!-- <div>
          分页
        </div> -->
        <div style="height:100px">
          
        </div>
      </div>
        </div>

        <div style="height: auto; background: #070d21; position: relative;">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="transform: scaleY(-1); margin-top: -0.5vw"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
      <Copyright />
        </div>
    </div>
</template>

<script>
import Copyright from "../../components/Copyright.vue";
export default {
    data() {
        return {
            successdata:[],
            currentPage: 1,
      totalCount: 1,
      pageSizes: [1, 2, 3, 4],
      PageSize: 6,
      scrollTop: '', 
        }
    },
    created() {
        this.getsuccess()
    },
    methods: {
        getsuccess() {
        this.$http.get('/cases/getCases').then(res =>{
        // console.log(res)
        this.successdata = res.data.data
        for(let item in this.successdata) {
          // console.log(this.Cases[item].imageName)
          this.successdata[item].imageName = this.successdata[item].imageName.split(",")[0]
          // console.log("1:",this.Cases,"2:",this.Cases[item].imageName,"3:",this.Cases[item].imageName.split(",")[0])
          this.successdata[item].imageName = this.successdata[item].imageName + "_s200"
        }
        // console.log(this.successdata)
      })
      },
      handleSizeChange(val) {
        this.currentPage= val
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.currentPage= val
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        // console.log(`当前页: ${val}`);
      }
    },
    components: {
    Copyright,
  },
}
</script>

<style>
.su_lb_zj{
    min-height: 40vw; width: 100%;
}
.su_lb_zj_h1{
  margin: 0 auto; width:75%;  -webkit-user-drag: none; 
}
.su_lb_zj_h1 li{
  font-size:1.2vw;list-style:none; border-bottom: 1px dashed #c5c5c5; padding-top: 2vw; position: relative;
}
</style>