<template>
    <div class="cpmo_h5">
        <header_h5 />
    <el-table class="customer-table" :show-header="false" :data="monewsdata_h5" style="width: 100%; margin-top:15px;">
      <el-table-column label="主图" width="130">
         <template slot-scope="scope" style="vertical-align:middle; ">
           <router-link style="display: inline-block;height: 100px;" :to="{name:'detnews_h5',params:{id: scope.row.id}}">
             <el-image :src="scope.row.imageName" fit="cover" style="idth: 100px; height: 100px;">
              <div slot="error" style="text-align: center;">
                <img src="../../assets/beijing/un_img.png" style="width: 100px; height:100px;"/>
              </div>
            </el-image>
           </router-link>
         </template>
      </el-table-column>

      <el-table-column label="内容">
          <template slot-scope="scope" id="22444">
          <router-link :to="{name:'detnews_h5',params:{id: scope.row.id}}">
          <p class="c_bt_h5" :title="scope.row.title">{{ scope.row.title }}</p>
          </router-link>
          <router-link :to="{name:'detnews_h5',params:{id: scope.row.id}}">
          <p class="c_jj_h5">{{ scope.row.synopsis }}</p>
          </router-link>
          <p class="c_sj_h5">{{ scope.row.dateTime }}</p>
        </template>
      </el-table-column>
    </el-table>
    <div style="height:50px;"></div>
    </div>
</template>

<script>
import header_h5 from "../components_h5/header_h5.vue"
export default {
    data() {
        return {
            monewsdata_h5:[],
            kt_h5:'../../assets/beijing/un_img.png'
        }
    },
    created() {
        this.getmonews_h5();
    },
    methods: {
        getmonews_h5() {
            this.$http.get("/new/getNew").then((res) => {
                // console.log("1:",res)
                // this.mocpdata_h5 = res.data.data.length
                // console.log(this.mocpdata_h5)
                this.monewsdata_h5 = res.data.data
                // console.log("2:",this.monewsdata_h5)
                for(let item in this.monewsdata_h5) {
                    this.monewsdata_h5[item].imageName = this.monewsdata_h5[item].imageName.split(",")[0]
                }
                // console.log("3:",this.monewsdata_h5)
            });
        }
    },
    components:{header_h5}
}
</script>

<style>
.cpmo_h5 .el-table th.gutter{
  display:table-cell !important;
}
.cpmo_h5 .el-table colgroup.gutter{
  display:table-cell !important;
}
table{
  width:100%  !important;
}

/* .cpmo_h5 .cell{
  margin-top: -15px !important; margin-bottom: -30px;
}
.cpmo_h5 .el-table__row{
    height: 125px;
}
.c_bt_h5{
  color: #333; font-size: 18px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 10px;
}
.c_jj_h5{
  color: #666; font-size: 14px; margin: 1vw 0 2vw 0;overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
}
.c_sj_h5{
  color: #999; float: right; margin-bottom: 2vw; right: 2vw; font-size: 12px;
}
.customer-table th{
    border:none !important;
  }
.customer-table td,.customer-table th.is-leaf {
  border:none !important;
}
.el-table::before{
  height: 0px !important;
} */
</style>