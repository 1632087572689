<template>
  <div class="n_d_zt">
      <header_h5 />
      <div class="n_d_t_h5">
          {{detal_h5.title}}
      </div>
      <div class="n_d_s_h5">
        <p style="padding-bottom:10px;">
          {{detal_h5.dateTime}}
        </p>
      </div>
      <div class="n_d_m_h5" v-html="detal_m_h5"></div>
      <div style="width:100%; height:50px;"></div>
  </div>
</template>

<script>
import header_h5 from "../components_h5/header_h5.vue"
export default {
  data() {
    return {
      detal_h5: [],
      detal_m_h5: []
    };
  },
  props: ["id"],
  created() {
    this.getdeal_h5();
  },
  methods: {
    getdeal_h5() {
      this.$http.get("/cases/get", { params: { id: this.id } }).then((res) => {
        // console.log(res)
        this.detal_h5 = res.data.data;
        // console.log(this.detal_h5)
        this.$http
          .get("/cases/getContent", { params: { id: this.id } }).then((res) => {
            // console.log(res)
            this.detal_m_h5 = res.data.message;
            // console.log(this.detcp_m_h5)
          });
      });
    },
  },
  components: {header_h5}
};
</script>

<style>
.n_d_zt{
    overflow-x: hidden !important;
}
.n_d_m_h5 img{
    width: 100%; margin-top: 2vw; 
}
.n_d_t_h5{
    width: 80%; margin: 0 auto; text-align: center; padding-top: 15px; font-size: 5vw; color: #333;
}
.n_d_s_h5{
    text-align: center; width: 80%; padding-top: 4vw; color: #999; margin: 0 auto; border-bottom:1px solid rgb(209, 208, 208); font-size: 12px;
}
.n_d_m_h5{
    padding: 6vw 6vw 0 6vw; color: #666; letter-spacing: 0.2vw; line-height: 7vw;
}
</style>