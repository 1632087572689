// import VueRouter from "vue-router";
import Home from '../view/Home/index.vue'
import MoreNews from '../view/More/index.vue'
import Details from '../view/Details/index.vue'
import OneApplyIndex from '../view/Details/OneApplyIndex.vue'
import About from '../view/Details/OneApply.vue'
import AboutSS from '../view/Details/AboutDetails.vue'
import MoreProducts from '../view/More/MoreProducts.vue'
import ProductsDetails from '../view/Details/ProductsDetails.vue'
import MoreSuccess from '../view/More/MoreSuccess.vue'
import SuccessDetails from '../view/Details/SuccessDetails.vue'

import home_h5 from '../H5/home/index.vue'
import Morenews_h5 from '../H5/more_h5/Morenews_h5.vue'
import Detnews_h5 from '../H5/details_h5/Detnews_h5'
import Moregy_h5 from '../H5/more_h5/Moregy_h5'
import Morecp_h5 from '../H5/more_h5/Morecp_h5'
import Moreal_h5 from '../H5/more_h5/Moreal_h5'
import Detcp_h5 from '../H5/details_h5/Detcp_h5'
import Detal_h5 from '../H5/details_h5/Detal_h5'
var router = new VueRouter({
    routes:[
        {path:'/',redirect:'/OneApplyIndex',name:'OneApplyIndex',meta:{keepAlive:true}},
        // {path:'/home',component:Home,name:'Home',meta:{keepAlive:true}},
        // {path:'/MoreNews',component:MoreNews,meta:{keepAlive:true}},
        // {path:'/MoreNews/Details/:id',component:Details,name: 'Details',props:true},
        {path:'/About',name:"About", component:About},
        {path:'/OneApplyIndex',component:OneApplyIndex},
        // {path:'/AboutSS',component:AboutSS},
        // {path:'/MoreProducts',component:MoreProducts,meta:{keepAlive:true}},
        // {path:'/MoreProducts/ProductsDetails/:id',component:ProductsDetails,name: 'PrDetails',props:true},
        // {path:'/MoreSuccess',component:MoreSuccess},
        // {path:'/MoreSuccess/SuccessDetails/:id',component:SuccessDetails,name: 'SuDetails',props:true},
        // // h5路由
        // {path:'/home_h5',component:home_h5,meta:{keepAlive:true}},
        // {path:'/Morenews_h5',component:Morenews_h5},
        // {path:'/Detnews_h5/:id',component:Detnews_h5,name:'detnews_h5',props:true},
        // {path:'/Moregy_h5',component:Moregy_h5},
        // {path:'/Morecp_h5',component:Morecp_h5,meta:{keepAlive:true}},
        // {path:'/Moreal_h5',component:Moreal_h5,meta:{keepAlive:true}},
        // {path:'/Detcp_h5/:id',component:Detcp_h5,name:'detcp_h5',props:true},
        // {path:'/Detal_h5/:id',component:Detal_h5,name:'detal_h5',props:true},
    ],
    // scrollBehavior(to, from, savedPosition) {
    //     return { x: 0, y: 0 };
    //   },

    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
          //此处意思 即若回退页面，返回savedPosition，且页面不置顶
          return savedPosition
        } else {
          //此处意思 若页面跳转新页面，则页面置顶
          return { x: 0, y: 0 ,behavior:'smooth'}
        }
    }
})
// replace用这个
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
    return routerReplace.call(this, location).catch(error => error)
}
// push用这个
// const VueRouterPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (to) {
//   return VueRouterPush.call(this, to).catch(err => err)
// }
export default router