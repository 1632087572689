<template>
  <div id="time_h5" class="block">
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in news_h5.slice(0,6)"
        :key="index"
        :icon="item.icon"
        :type="item.type"
        :color="item.color"
        :size="item.size"
        :timestamp="item.dateTime"
      >
      <router-link :to="{name:'detnews_h5',params:{id: item.id}}">
        {{ item.title }}
      </router-link>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
    data() {
        return {
            news_h5:[]
        }
    },
    created() {
        this.getcxnews()
    },
    methods:{
        getcxnews() {
            // 新闻查询
            this.$http.get("/new/getNew").then((res) => {
                // console.log(res)
                this.news_h5 = res.data.data
                // console.log(this.news_h5)
            })
        }
    }
}
</script>

<style scoped>
.block{
    left: 10% !important;width: 80%;
}
</style>

<style>
#time_h5 .el-timeline-item__timestamp{
  color: #729ae4!important; text-shadow: 0.1vw 0.2vw 0.2vw #000; margin-top: 4% !important; font-size: 3vw !important;
}
#time_h5 .el-timeline-item__content{
  font-size: 15px !important;
}
</style>
