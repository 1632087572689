<template>
  <div>
    <div class="heard">
      <div class="newlb_left animate__animated animate__bounceIn">
        <img src="../../assets/logo/logo-04.png" alt="" />
      </div>
      <div class="newlb_right">
        <p class="newlb_w1 animate__animated animate__bounceIn">
          铸行业之<span class="newlb_w2">冠</span>
        </p>
        <p class="newlb_w3 animate__animated animate__bounceIn">
          立精英之<span class="newlb_w4">锐</span>
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="margin-top: 4.38%"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
    </div>
    
    <div style="position: relative; padding: 3vw 0 0 7vw">
      <el-button
        class="xq_fh animate__animated animate__fadeIn"
        icon="el-icon-back"
        circle
        style="font-size: 2vw"
        @click="$router.back()"
      ></el-button>
      <span class="new_xq_an">新闻列表</span>
    </div>
    <div class="xq_h animate__animated animate__fadeIn">
      <div class="xq_title">
        {{ detailsdata.title }}
      </div>
      <div class="xq_createtime">
        {{ detailsdata.dateTime }}
      </div>
      <div class="xq_content" v-html="detailsdatas">
        <!-- {{ detailsdata.content }} -->
      </div>
    </div>
    <div style="height: auto; background: #070d21; position: relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="transform: scaleY(-1); margin-top: -0.5vw"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
      <Copyright />
    </div>
  </div>
</template>

<script>
import Copyright from "../../components/Copyright.vue";
export default {
  data() {
    return {
      detailsdata: [],
      detailsdatas:[],
    };
  },
  props: ["id"],
  created() {
    this.getNewsInfo();
  },
  mounted() {},
  methods: {
    getNewsInfo() {
      // console.log(this.id)
      this.$http.get("/new/get", { params: { id: this.id } }).then((res) => {
        // console.log(res)
        this.detailsdata = res.data.data;
        // console.log(this.detailsdata)
        this.$http.get("/new/getContent", { params: { id: this.id } }).then((res) => {
            // console.log(res)
            this.detailsdatas = res.data.message;
            // console.log(this.detailsdatas)
            })
      });
    },
    
  },
  
  components: {
    Copyright,
  },
};
</script>

<style>
.xq_h {
  width: 100%;
  min-height: 50vw;
  margin-top: 3vw;
}
.xq_fh {
}
.xq_title {
  color: #333;
  width: 50%;
  font-size: 2vw;
}
.xq_createtime {
  color: #999;
  width: 65%;
  font-size: 1.2vw;
  padding: 2vw 0 2vw 0;
  border-bottom: 1px dashed #c5c5c5;
}
.xq_content {
  color: #333;
  text-indent: 2em;
  width: 75%;
  font-size: 1.6vw;
  padding: 2vw 0 2vw 0;
  font-weight: lighter;
  letter-spacing: 0.2vw;
}
.xq_title,
.xq_createtime {
  text-align: center;
}
.xq_title,
.xq_createtime,
.xq_content {
  margin: 0 auto;
}
.new_xq_an{
  padding-left: 1vw; font-size: 2vw; color: orangered;
}
.xq_content img{
  /* position: relative;
  left: calc(50% - 250px); */
  margin: 15px auto;
  display: block;
}
</style>