<template>
  <div id="Copyright" class="copyright">
    <p><img src="../H5/home/images/footer_img_h5.png" /></p>
    <p>© 2022 kuary 冠锐 闽ICP备12020945号</p>
    <p>福建冠锐网络技术有限公司</p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.copyright {
  margin-top: 50px;
  font-size: 16px;
  font-weight: lighter;
  color: #fff;
  width: 100%;
  text-align: center;
  z-index: 9;
  line-height: 40px;
  display: inline-block;
  padding-bottom: 50px;
}
.copyright img{width: 80px; height: 80px; display: inline-block;}
</style>