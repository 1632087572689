// import Vue from 'vue'
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import 'babel-polyfill' 

import App from './App.vue'
// import promise from "es6-promise";
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill()

// import VueRouter from 'vue-router';
import router from './router/router.js'

// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from './axios.js'
import '@/assets/font/font.css'

Vue.use(VueRouter)
// Vue.use(ElementUI)
Vue.use(axios)

Vue.config.productionTip = false

// Vue.prototype.$echarts = echarts
// promise.polyfill();
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')


// "vant": "latest-v2",删除的