<template>
    <div>
        <div class="heard">
      <div class="newlb_left animate__animated animate__bounceIn">
        <img src="../../assets/logo/logo-04.png" alt="" />
      </div>
      <div class="newlb_right">
        <p class="newlb_w1 animate__animated animate__bounceIn">
          铸行业之<span class="newlb_w2">冠</span>
        </p>
        <p class="newlb_w3 animate__animated animate__bounceIn">
          立精英之<span class="newlb_w4">锐</span>
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="margin-top: 4.38%"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
        </div>

        <div style="position: relative; padding: 3vw 0 0 7vw">
      <el-button
        class="xq_fh animate__animated animate__fadeIn"
        icon="el-icon-back"
        circle
        style="font-size: 2vw"
        onClick="javascript:history.back(-1);"
      ></el-button>
      <span class="pr_xq_an">案例列表</span>
        </div>

        <div style="min-height:40vw;width:100%">
          <div class="al_xq_title">
            {{Sudata.title}}
          </div>
          <div class="al_xq_sj">
            {{Sudata.dateTime}}
          </div>
          <div class="al_xq_nr" v-html="Sudatas">
            <!-- {{Sudata.content}} -->
          </div>
          <!-- <div style="text-align: center;">
            <el-image
              style="width: 45vw;"
              :src="Sudata.imageName"
              fit="cover">
            </el-image>
          </div> -->
        </div>

        <div style="height: auto; background: #070d21; position: relative;">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        aria-hidden="true"
        class="position-absolute top-0 width-full mt-n1"
        style="transform: scaleY(-1); margin-top: -0.5vw"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
      <Copyright />
        </div>
    </div>
</template>

<script>
import Copyright from "../../components/Copyright.vue";
export default {
    data() {
        return {
            Sudata:[],
            Sudatas:[]
        }
    },
    props:["id"],
    created() {
        this.getSuInfo()
    },
    methods: {
        getSuInfo() {
            // console.log(this.id)
            this.$http.get("/cases/get", { params: { id: this.id } }).then((res) => {
            // console.log(res)
            this.Sudata = res.data.data;
            // console.log(this.Sudata);

            this.$http.get("/cases/getContent", { params: { id: this.id } }).then((res) => {
            // console.log(res)
            this.Sudatas = res.data.message;
            // console.log(this.Sudatas)
            })
      });
    }
    },
    components: {
    Copyright,
  },
}
</script>

<style>
.al_xq_title{
  color: #333; width: 50%; font-size: 2vw; text-align: center;
}
.al_xq_sj{
  color: #999; width: 65%; font-size: 1.2vw; padding: 2vw 0 2vw 0; border-bottom: 0.14vw dashed #c5c5c5; text-align: center;
}
.al_xq_nr{
  color: #333; text-indent: 2em; width: 75%; font-size: 1.6vw; padding: 2vw 0 2vw 0; font-weight: lighter; letter-spacing: 0.2vw;
}
.al_xq_nr img{
  margin: 15px auto;display: block;
}
.al_xq_nr,.al_xq_sj,.al_xq_title{
  margin: 0 auto;
}
</style>