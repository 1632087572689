<template>
  <div>
    <div class="news_d_h5">
      <p class="news_d_f_h5 el-icon-back" @click="back"></p>
      <img class="news_d_tp1_h5" src="http://qigugu.ifchus.com/logo-04.png" alt="" />
    </div>
    <div class="news_t_h5" style="min-height: 60vw; width: 100%">
      <div style="width: 70%; margin: 0 auto; padding-top: 21vw">
        <p class="x_w1_h5">
          铸行业之<span class="x_w3_h5">冠</span>
        </p>
        <p class="x_w2_h5">
          立精英之<span class="x_w3_h5">锐</span>
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 1680 40"
        class="position-absolute width-full z-1"
        style="position: absolute; top: 58vw"
      >
        <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  methods:{
    back(){
        if (window.history.length <= 1) {
            this.$router.push({path:'/home_h5'})
            return false
        } else {
            this.$router.go(-1)
        }
    }
  }
}
</script>

<style>
.news_d_h5{
  position: fixed; color: red; z-index: 100; width: 100%;height: 50px;
}
.x_w1_h5{
  text-align: left; color: white; font-size: 10vw; font-family: 'hl';
}
.x_w2_h5{
  text-align: right; color: white; font-size: 10vw; font-family: 'hl';
}
.x_w3_h5{
  font-size: 14vw; color: orangered;
}
.news_t_h5{
  background: #070d21 url(../../assets/beijing/hero-glow.svg) center center no-repeat;
}
.news_d_h5{
  height: 50px; background-color: white; width: 100%; text-align: center;
}
.news_d_f_h5{
  position: absolute; top: 15px; left: 15px;text-align: left; font-size: 20px;
}
.news_d_tp1_h5{
  width: 100px; position: absolute; top:15px; left: calc(50% - 50px); 
}
</style>